(function ($, window, undefined) {

    function AccordionContent(args) {
        this.defaults = {
            firstOpen: true,
            allOpen: false,
            mobileOnly: false,
            resetOnViewportChange: false,
            selectors: {
                widgetContainer: '.accordion-content',
                accordionItem: '.accordion-item',
                btnToggle: '.btn-toggle',
                contentBlock: '.content-block',
                btnToggleAll: '.btn-toggle-all'
            }
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$accordionItem = this.parent(this.options.selectors.accordionItem);
        this.$btnToggle = this.parent(this.options.selectors.btnToggle);
        this.$btnToggleAll = this.parent(this.options.selectors.btnToggleAll);

        if (this.options.mobileOnly === true){
            if(SVHA.Utility.StateManager.ssm.isActive('mobile')){
                this.setDefaultState();
            }
        } else {
            this.setDefaultState();
        }
        this.bindEvents();
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    AccordionContent.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };


    /*
    UI events
     */
    AccordionContent.prototype.toggleAccordion = function (targetItem) {
        if (targetItem.hasClass('active')){
            this.closeAccordion(targetItem)
        } else {
            this.openAccordion(targetItem)
        }
    };

    AccordionContent.prototype.openAccordion = function (targetItem) {
        targetItem.addClass('active');
        var targetContent = targetItem.find(this.options.selectors.contentBlock);
        var openHeight = targetContent.attr('data-open-height');

        targetContent.css({
            height: openHeight+'px',
            display: 'block'
        });
    };

    AccordionContent.prototype.closeAccordion = function (targetItem) {
        targetItem.removeClass('active');
        var targetContent = targetItem.find(this.options.selectors.contentBlock);
        targetContent.css({
            height: 0
        });
    };

    AccordionContent.prototype.toggleAll = function () {
        var self = this;
        if (this.options.allOpen === false){

            this.$accordionItem.each(function(){
               self.openAccordion($(this));
            });

            this.options.allOpen = true;

        } else {

            this.$accordionItem.each(function(){
                self.closeAccordion($(this));
            });

            this.options.allOpen = false;
        }
    };

    /*
    Default state and event binding
     */
    AccordionContent.prototype.setDefaultState = function () {
        var self = this;
        this.$accordionItem.each(function(index){
            var $itemContent = $(this).find(self.options.selectors.contentBlock);
            var defaultHeight = $itemContent.height();

            $itemContent.attr('data-open-height', defaultHeight);
            $itemContent.css({
                display: 'block',
                height: '0px'
            });

            // Open first if options says so AND if it's not in the footer
            if(self.options.firstOpen === true && self.$widgetContainer[0].id != 'main-footer'){
                //Open the first
                if (index === 0){
                    self.openAccordion($(this));
                }
            }
        });
    };

    /*
    Reset accordions
     */
    AccordionContent.prototype.resetAccordions = function () {
        var self = this;
        this.$accordionItem.each(function(index){
            var $itemContent = $(this).find(self.options.selectors.contentBlock);

            $itemContent.removeAttr('style');
        });
    };


    AccordionContent.prototype.bindEvents = function () {
        var self = this;

        this.$btnToggle.each(function(){

            $(this).click(function () {
                var targetItem = $(this).parents(self.options.selectors.accordionItem);
                self.toggleAccordion(targetItem);
            });
        });

        this.$btnToggleAll.click(function(){
            self.toggleAll();
        });

        if (this.options.resetOnViewportChange === true){
            SVHA.Utility.StateManager.addOnEnter('desktop', function() {
                self.resetAccordions();
            });

            SVHA.Utility.StateManager.addOnEnter('mobile', function() {
                self.setDefaultState();
            });
        }
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.AccordionContent = AccordionContent;

})(jQuery, window);
