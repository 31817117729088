var Forms = (function() {
	var selectors = {
		form: 'form[data-ktc-ajax-update]',
		pageNumber: '#page-number'
	};

	var invisibleRecaptchaOnloadCallback = function() {
		$('[data-recaptcha]').each(function() {
			var $recaptchaElement = $(this);
			
			// Ignore if already rendered;
			if (!!$recaptchaElement.attr('data-recaptcha'))
				return;
	
			var widgetId = grecaptcha.render($recaptchaElement.attr('id'), {
				'callback' : function(token) {
					$recaptchaElement.closest('form').find('[type=submit]').trigger('click');
				}
			}, true);
	
			// Set the widget id against the element
			$recaptchaElement.attr('data-recaptcha', widgetId);
		});
	};

	return {
		Init: function() {
			var _self = this;
			// Set up global on load callback
			window.invisibleRecaptchaOnloadCallback = invisibleRecaptchaOnloadCallback;

			this.Bindings();

			$(function() {
				_self.CustomJqueryValidateRules();
			});
		},
		Bindings: function() {
			$(selectors.form).each(function(_, form) {
				var $form = $(form);

				// Bind mutation observer
				var observer = new MutationObserver(function(mutations, observer) {
					// Rebind recaptcha and this bindings on update
					if (mutations.filter(function(mutation) { return mutation.type === 'childList' }).length) {
						invisibleRecaptchaOnloadCallback();
						Forms.Bindings();
					}
				});
				observer.observe(form.parentElement, {
					childList: true
				});

				var formClickHandler = function(e) {
					e.preventDefault();
					e.stopPropagation();

					var button = this;

					// Check for recaptcha first
					if (window.grecaptcha && $form.find('[data-recaptcha]').length) {
						var recaptchaId = $form.find('[data-recaptcha]').attr('data-recaptcha');
						
						if (!window.grecaptcha.getResponse(recaptchaId).length) {
							window.grecaptcha.execute();
							
							return false;
						}
					}
					

					// Ensure form is valid, assuming jQuery validation is available
					if ($.validator && !$(form).valid()) {
						return false;
					}

					// Remove this listener (if needed after form submit, the MutationObserver will re-add it) and try clicking again
					button.removeEventListener('click', formClickHandler);
					// We have to wait a short time before attempting to click - for some reason if the user doesn't do anything wrong
					// the button won't click
					requestAnimationFrame(function() {
						button.click();
					});
				};

				$form.find('[type=submit]').each(function(_, submit) {
					submit.addEventListener('click', formClickHandler);
				});
			});
		},
		CustomJqueryValidateRules: function() {
			if(!$.validator)
				return;

			$.validator.addMethod("checkbox-required", function(value, element) {
				return value === 'true' && element.checked;
			}, function(params, element) {
				return $(element).data('val-checkbox-required');
			});

			$.validator.unobtrusive.adapters.add('checkbox-required', [], function (options) {
				options.rules['checkbox-required'] = {};
				options.messages['checkbox-required'] = options.message;
			});
		},
		IncrementPageGeneric: function() {
			var el = document.querySelector(selectors.pageNumber);

			if(!!el) {
				var currentPage = parseInt(el.value);
				if(!isNaN(currentPage)) {
					el.value = ++currentPage;
				}
			}
		},
		HideLoadMore: function(selector) {
			var el = document.querySelector(selector);

			if(!!el) {
				el.style.display = 'none';
			}
		}
	};
})();
(function () {
	Forms.Init();
})();