// JSON data file url
var _dataUrl = "/PerformanceData/GetAll";

// Default Messages --> it will be updated when .json file is loaded.
var _messages = {
	overallRatingSubtitle: 'Percentages of Overall Ratings',
	overallRatingDescription: 'Description for (Overall Ratings)',
	recommendationDescription: 'Description for (Patent Recommendation)',
	handHygieneDescription: 'Description for (Hand Hygiene)',
	infectionsDescription: 'Infections per 10,000 patient care day',
	pressureInjuriesDescription: 'Description for (Pressure Injuries)',
	fallsHarmDescription: 'Description for (Falls with Harm)',
	fallDeathDescription: 'Description for (Fall-related Deaths)',
	medicationDeathDescription: 'Description for (Medication-related Deaths)',
};

function removeHash() {
	history.pushState("", document.title, window.location.pathname + window.location.search);
}

$(document).ready(function() {
	// MAIN PAGE INIT
	if ($('#svha-chart-main').length == 1) {
		hospitalName = decodeURIComponent(location.hash.substring(1, location.hash.length));
		if (hospitalName != ""){
			$('#hospitalTitle').text(hospitalName);
		} else {
			$('#hospitalTitle').text('');
		}

		$.getJSON( _dataUrl, function( data ) {
			if (_data == null) {
				_data = data;
        set_messages();
				set_dropdown();
				show_list();
				$('.c_dropdown').selectric().on('change', function() {
					if ($(".c_dropdown option:selected").attr('value')){
						hospitalName = $(".c_dropdown option:selected").text();
						//console.log("hospital name: " + hospitalName);
						if (hospitalName){
							show_list('update');
						}
						if (hospitalName.length > 0) {
							location.hash = hospitalName;
							$('#hospitalTitle').text(hospitalName);
						} else {
							history.pushState("", document.title, window.location.pathname
                                                       + window.location.search);
							$('#hospitalTitle').text('');
						}
					}
				});
			}
		});
	}
	// DETAIL PAGE INIT
	else if ($('#svha-chart-detail').length == 1) {
		hospitalName = getUrlParameter('hospitalName');
		graphType  = getUrlParameter('graphType');
		$('#detail-'+graphType).removeClass('hidden');

		$.getJSON( _dataUrl, function( data ) {
			if (_data == null) {
				_data = data;
				set_messages();
				set_dropdown_view();
				show_view(graphType);

				$('.c_dropdown').selectric().on('change', function() {
					SelectHospital = $(".c_dropdown option:selected").text();
					//dropdown trigger
                    //debugger;
					var tmp = getTermData('hospitalInfos', SelectHospital, graphType);
					if (tmp){

						show_chart(SelectHospital,tmp);
					}
				});
			}
		});
	}
});

$(function() {
  equalheight('.equalsRow .graph-container');
});

$(window).resize(function(){
  equalheight('.equalsRow .graph-container');
});


function draw_MedicationDeaths(objId){
	var root = this;

	this.obj = $(objId).get(0);
	this.datas = $(this.obj).data();
	this.data = [];
	this.setting = function(data){
		if ( $(root.obj).find('svg').length != 0 ){
			$(root.obj).empty();
		}
		if (data){
			root.datas['field'] = data;
		}
		for ( key in root.datas['field'] ){
			root.data.push( parseFloat(root.datas['field'][key]) );
		}

		root.max = root.data.length;
		root.viewBoxW = 200;
		root.viewBoxH = 200;

		root.colors = [
			'#ffffff',
			'#fcd12a',
			'#ff5046'
		];

		root.paper = null;
		root.paper = Raphael(root.obj);

		root.paper.canvas.setAttribute("id", "svgMedicationDeaths");
		root.paper.setViewBox(0,0,root.viewBoxW,root.viewBoxH, true);
		//root.paper.canvas.setAttribute('preserveAspectRatio', 'none');
		root.paper.canvas.removeAttribute('width');
		root.paper.canvas.removeAttribute('height');
		root.paper.canvas.removeAttribute('style');

		root.maxData = Math.max.apply(null, root.data);
		root.draw();
	}
	this.makeHTML = function(){
		if ( !$(root.obj).next().hasClass('roundBox') ) return false;

		var outputHTML = '';
		outputHTML += '<ul class="'+root.datas['type']+'" style="display:none;">'
		for ( key in root.datas['field'] ){
			//var color = "color:"+root.colors[2]+";";
			var color = "color:#000;";
			var bgcolor = "background-color:"+root.colors[2]+";";
			var data = root.datas['field'][key];
			var displayData = data;

			if (!data){
				data = 'null';
//                displayData = "N/A";
			}else{
				data = data;
/*
                if (data == 0.00){
                    displayData = "N/A";
                }
                else {
                    displayData = data;
                }
*/                
			}


			outputHTML += '<li>';
			outputHTML += '<div class="table-row">';
			//outputHTML += '<div class="cell first"><i class="bullet" style="'+bgcolor+'"></i></div>';
			outputHTML += '<div class="cell"><strong class="title">'+key+'</strong></div>';
			// outputHTML += '<div class="cell"><span class="value" style="'+color+'">'+data+'</span></div>';
			outputHTML += '<div class="cell"><span class="value">'+displayData+'</span></div>';
			outputHTML += '</div>';
			outputHTML += '</li>';
		}
		outputHTML += '</ul>\
		<span class="additional-text text-small"><i>' + _messages.medicationDeathDescription + '</i></span>';
		$(root.obj).next().html(outputHTML);
		$(root.obj).next().find('ul').delay(500).slideDown(800,function(){
			equalheight('.equalsRow .graph-container');
		});

	}

	this.draw = function(){
		root.ellipse = root.paper.ellipse(99.5, 133.5, 47.5, 10.5);
		root.ellipse.attr({
			'opacity' : 0.05,
			'fill' : '#000'
		});

		root.set_bottle = root.paper.set();
		root.set_bottle.push(
			root.paper.path('M117.89,64.83H87.2s0-2.63,0-4.47h0c0-1.85,1.86-3.36,4.13-3.36h22.43c2.27,0,4.13,1.51,4.13,3.36h0C117.89,62.2,117.89,64.83,117.89,64.83Z').attr('fill', root.colors[0]),
			root.paper.path('M112,63.86H92.5a12,12,0,0,0-12,12v40.41a12,12,0,0,0,12,12H112a12,12,0,0,0,12-12V75.86A12,12,0,0,0,112,63.86Z').attr('fill', root.colors[0]),
			root.paper.path('M112,63.86H92.5a12,12,0,0,0-12,12v2.93H124V75.86A12,12,0,0,0,112,63.86Z').attr('fill', root.colors[1]),
			root.paper.path('M80.5,116.27a12,12,0,0,0,12,12H112a12,12,0,0,0,12-12v-2.93H80.5Z').attr('fill', root.colors[1])

		);
		root.set_plus = root.paper.set();
		root.set_plus.push(
			root.paper.rect(100.56, 87.43, 3.36, 17.27, 1.37, 1.37).attr('fill', root.colors[2]),
			root.paper.rect(100.56, 87.43, 3.36, 17.27, 1.37, 1.37).attr('fill', root.colors[2]).transform('r90')
		);
		root.set_pill_1 = root.paper.set();
		root.set_pill_1.push(
			root.paper.path('M69.65,133.79l-8.45.74A5,5,0,0,1,55.75,130l0-.47A5,5,0,0,1,60.29,124l8.4-.73').attr('fill',root.colors[2]),
			root.paper.path('M68.69,123.31l8.4-.73a5,5,0,0,1,5.45,4.57l0,.47A5,5,0,0,1,78,133.06l-8.35.73').attr('fill',root.colors[0])
		);
		root.set_pill_2 = root.paper.set();
		root.set_pill_2.push(
			root.paper.path('M129.48,123.6l8.41,1.14a5,5,0,0,1,4.31,5.66l-.06.46a5,5,0,0,1-5.66,4.31L128.12,134').attr('fill',root.colors[2]),
			root.paper.path('M128.12,134l-8.36-1.14a5,5,0,0,1-4.31-5.66l.06-.46a5,5,0,0,1,5.66-4.31l8.31,1.13').attr('fill',root.colors[0])
		);
		root.set_pill_3 = root.paper.set();
		root.set_pill_3.push(
			root.paper.path('M84.44,132.93l-7.47-4a5,5,0,0,1-2-6.82l.22-.41a5,5,0,0,1,6.82-2l7.42,4').attr('fill',root.colors[2]),
			root.paper.path('M89.4,123.63l7.42,4a5,5,0,0,1,2,6.82l-.22.41a5,5,0,0,1-6.82,2l-7.38-4').attr('fill',root.colors[0])
		);

		root.set_bottle.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});

		root.set_plus.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});

		root.set_pill_1.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});
		root.set_pill_2.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});
		root.set_pill_3.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});

		root.ellipseAni = Raphael.animation({
			'opacity' : .05
		}, 1000);
		root.opacityAni = Raphael.animation({
			'opacity' : 1
		}, 1000);

		root.ellipse.animate(root.ellipseAni.delay(0));

		root.set_bottle.animate(root.opacityAni.delay(1000));
		root.set_plus.animate(root.opacityAni.delay(1300));
		root.set_pill_1.animate(root.opacityAni.delay(1500));
		root.set_pill_2.animate(root.opacityAni.delay(1700));
		root.set_pill_3.animate(root.opacityAni.delay(1900));

		root.makeHTML();

	}
	//this.setting(data);
}

function draw_FallsWithDeath(objId){
	var root = this;

	this.obj = $(objId).get(0);
	this.datas = $(this.obj).data();

	this.setting = function(data){
		if ( $(root.obj).find('svg').length != 0 ){
			$(root.obj).empty();
		}
		root.data = [];
		root.datas['field'] = data;

		for ( key in root.datas['field'] ){
			root.data.push( parseFloat(root.datas['field'][key]) );
		}

		root.max = root.data.length;

		root.viewBoxW = 200;
		root.viewBoxH = 200;

		root.colors = [
			'#ffffff',
			'#a7a9ac',
			'#25bff0',
			'#80dbf8'
		];

		root.paper = null;
		root.paper = Raphael(root.obj);
		root.paper.canvas.setAttribute("id", "svgFallsWithDeath");
		root.paper.setViewBox(0,0,root.viewBoxW,root.viewBoxH, true);
		//root.paper.canvas.setAttribute('preserveAspectRatio', 'none');
		root.paper.canvas.removeAttribute('width');
		root.paper.canvas.removeAttribute('height');
		root.paper.canvas.removeAttribute('style');

		root.maxData = Math.max.apply(null, root.data);

		root.draw();
	}

	this.draw = function(){
		root.ellipse = root.paper.ellipse(100, 138, 54, 8);
		root.ellipse.attr({
			'opacity' : 0.05,
			'fill' : '#000'
		});

		root.set_person = root.paper.set();
		root.set_person.push(
			root.paper.circle(64.94, 68.25, 9.76).attr('fill', root.colors[1]),
			root.paper.path('M85.08,55h53.73a8,8,0,0,1,8,8V76.45a10,10,0,0,1-10,10H85.08a8,8,0,0,1-8-8V63a8,8,0,0,1,8-8Z').attr('fill', root.colors[0])
		);
		root.set_bed = root.paper.set();
		root.set_bed.push(
			root.paper.rect(58.33, 84.15, 4.94, 51.26, 2.47, 2.47).attr('fill', root.colors[3]),
			root.paper.rect(136.19, 84.15, 4.94, 51.26, 2.47, 2.47).attr('fill', root.colors[3]),
			root.paper.rect(60.68, 93.64, 78.1, 4.18, 2.09, 2.09).attr('fill', root.colors[3]),
			root.paper.rect(42.42, 79.79, 114.61, 8.72, 4.36, 4.36).attr('fill', root.colors[2]),
			root.paper.circle(60.8, 132.98, 5.76).attr('fill', root.colors[1]),
			root.paper.circle(138.66, 132.98, 5.76).attr('fill', root.colors[1])
		);

		root.set_person.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});
		root.set_bed.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});

		root.ellipseAni = Raphael.animation({
			'opacity' : .05
		}, 1000);
		root.opacityAni = Raphael.animation({
			'opacity' : 1
		}, 1000);

		root.ellipse.animate(root.ellipseAni.delay(0));

		root.set_bed.animate(root.opacityAni.delay(1000));
		root.set_person.animate(root.opacityAni.delay(1500));

		root.makeHTML();
	}

	this.makeHTML = function(){
		if ( !$(root.obj).next().hasClass('roundBox') ) return false;

		var outputHTML = '';
		outputHTML += '<ul class="'+root.datas['type']+'" style="display:none;">'
		for ( key in root.datas['field'] ){
			//var color = "color:"+root.colors[2]+";";
			var color = "color:#000;";
			var bgcolor = "background-color:"+root.colors[2]+";";
			var data = root.datas['field'][key];
			var dataDisplay = data;
			if (!data){
				data = 'null';
//                displayData = 'N/A';
			}else{
				data = data;
        /*
                if (data == 0.00){
                    dataDisplay = "N/A";
                }
                else {
                    dataDisplay = data;
                }
         */
			}

			outputHTML += '<li>';
			outputHTML += '<div class="table-row">';
			//outputHTML += '<div class="cell first"><i class="bullet" style="'+bgcolor+'"></i></div>';
			outputHTML += '<div class="cell"><strong class="title">'+key+'</strong></div>';
			// outputHTML += '<div class="cell"><span class="value" style="'+color+'">'+data+'</span></div>';
			outputHTML += '<div class="cell"><span class="value">'+dataDisplay+'</span></div>';
			outputHTML += '</div>';
			outputHTML += '</li>';
		}
		outputHTML += '</ul>\
		<span class="additional-text text-small"><i>' + _messages.fallDeathDescription + '</i></span>';
		$(root.obj).next().html(outputHTML);
		$(root.obj).next().find('ul').delay(500).slideDown(800,function(){
			equalheight('.equalsRow .graph-container');
		});

	}
	//this.makeHTML();
}

function draw_FallsWithHarm(objId){
	var root = this;

	this.obj = $(objId).get(0);
	this.datas = $(this.obj).data();

	this.setting = function(data){
		if ( $(root.obj).find('svg').length != 0 ){
			$(root.obj).empty();
		}
		root.data = [];
		root.datas['field'] = data;
		for ( key in root.datas['field'] ){
			root.data.push( parseFloat(root.datas['field'][key]) );
		}

		root.max = root.data.length;

		root.viewBoxW = 200;
		root.viewBoxH = 200;

		root.colors = [
			'#ffffff',
			'#e0a4ff',
			'#c771f3',
			'#25bff0'
		];

		root.paper = null;
		root.paper = Raphael(root.obj);
		root.paper.canvas.setAttribute("id", "svgInjuries");
		root.paper.setViewBox(0,0,root.viewBoxW,root.viewBoxH, true);
		//root.paper.canvas.setAttribute('preserveAspectRatio', 'none');
		root.paper.canvas.removeAttribute('width');
		root.paper.canvas.removeAttribute('height');
		root.paper.canvas.removeAttribute('style');

		root.maxData = Math.max.apply(null, root.data);

		root.draw();
	}

	this.draw = function(){
		root.ellipse = root.paper.ellipse(100.5, 158, 29.5, 6);
		root.ellipse.attr({
			'opacity' : 0.05,
			'fill' : '#000'
		});

		root.set_person = root.paper.set();
		root.set_person.push(
			root.paper.circle(97.88, 47.65, 11.56).attr('fill', root.colors[1]),
			root.paper.rect(102.39, 100.69, 9.4, 57.56, 3.5, 3.5).attr('fill', root.colors[2]),
			root.paper.path('M95.35,129.46V104.19a3.51,3.51,0,0,0-3.5-3.5h-2.4a3.51,3.51,0,0,0-3.5,3.5v25.28Z').attr('fill', root.colors[2]),
			root.paper.rect(85.95, 130.17, 9.4, 5).attr('fill', root.colors[0]),
			root.paper.rect(85.95, 135.98, 9.4, 5).attr('fill', root.colors[0]),
			root.paper.rect(85.95, 135.17, 9.4, 0.81).attr('fill', root.colors[2]),
			root.paper.path('M85.95,141.68v13.06a3.51,3.51,0,0,0,3.5,3.5h2.4a3.51,3.51,0,0,0,3.5-3.5V141.68Z').attr('fill', root.colors[2]),
			root.paper.path('M85.95,61.92h21.84a4,4,0,0,1,4,4v54.73a0,0,0,0,1,0,0H85.95a0,0,0,0,1,0,0V61.92A0,0,0,0,1,85.95,61.92Z').attr('fill', root.colors[2]),
			root.paper.path('M85.94,61.92h3.4a0,0,0,0,1,0,0v49.36a3.5,3.5,0,0,1-3.5,3.5h-2.4a3.5,3.5,0,0,1-3.5-3.5V67.92a6,6,0,0,1,6-6Z').attr('fill', root.colors[2])
		);
		root.bar = root.paper.rect(115.31, 61.92, 5.87, 96.32, 2.5, 2.5).attr('fill', root.colors[3]);

		root.set_person.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});
		root.bar.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});

		root.ellipseAni = Raphael.animation({
			'opacity' : .05
		}, 1000);
		root.opacityAni = Raphael.animation({
			'opacity' : 1
		}, 1000);

		root.ellipse.animate(root.ellipseAni.delay(0));

		root.set_person.animate(root.opacityAni.delay(1000));
		root.bar.animate(root.opacityAni.delay(1500));

		root.makeHTML();
	}



	this.makeHTML = function(){
		if ( !$(root.obj).next().hasClass('roundBox') ) return false;

		var outputHTML = '';
		outputHTML += '<ul class="'+root.datas['type']+'" style="display:none;">'
		for ( key in root.datas['field'] ){
			//var color = "color:"+root.colors[2]+";";
			var color = "color:#000;";
			var bgcolor = "background-color:"+root.colors[2]+";";
			var data = root.datas['field'][key];
			var dataDisplay = data;
			if (!data){
				data = 'null';
//                displayData = "N/A";
			}else{
				data = data;
        /*
                if (data == 0.00){
                    dataDisplay = "N/A";
                }
                else {
                    dataDisplay = data;
                }
         */
			}

			outputHTML += '<li>';
			outputHTML += '<div class="table-row">';
			//outputHTML += '<div class="cell first"><i class="bullet" style="'+bgcolor+'"></i></div>';
			outputHTML += '<div class="cell"><strong class="title">'+key+'</strong></div>';
			// outputHTML += '<div class="cell"><span class="value" style="'+color+'">'+data+'</span></div>';
			outputHTML += '<div class="cell"><span class="value">'+dataDisplay+'</span></div>';
			outputHTML += '</div>';
			outputHTML += '</li>';
		}
		outputHTML += '</ul>\
		<span class="additional-text text-small"><i>' + _messages.fallsHarmDescription + '</i></span>';
		$(root.obj).next().html(outputHTML);
		$(root.obj).next().find('ul').delay(500).slideDown(800,function(){
			equalheight('.equalsRow .graph-container');
		});

	}
	//this.makeHTML();
}

function draw_Injuries(objId){
	var root = this;

	this.obj = $(objId).get(0);
	this.datas = $(this.obj).data();

	this.colors = [
		'#fcd12a',
		'#ffe35a',
		'#ff5046'
	];

	this.setting = function(data){
		if ( $(root.obj).find('svg').length != 0 ){
			$(root.obj).empty();
		}
		root.data = [];
		root.datas['field'] = data;
		for ( key in root.datas['field'] ){
			root.data.push( parseFloat(root.datas['field'][key]) );
		}

		root.max = root.data.length;

		root.viewBoxW = 200 ;
		root.viewBoxH = 200;

		root.paper = null;
		root.paper = Raphael(root.obj);
		root.paper.canvas.setAttribute("id", "svgInjuries");
		root.paper.setViewBox(0,0,root.viewBoxW,root.viewBoxH, true);
		//root.paper.canvas.setAttribute('preserveAspectRatio', 'none');
		root.paper.canvas.removeAttribute('width');
		root.paper.canvas.removeAttribute('height');
		root.paper.canvas.removeAttribute('style');

		root.maxData = Math.max.apply(null, root.data);

		this.draw();
	}

	this.draw = function(){
		root.ellipse = root.paper.ellipse(99.5, 145, 50.5, 7);
		root.ellipse.attr({
			'opacity' : 0.05,
			'fill' : '#000'
		});

		root.set_person = root.paper.set();
		root.set_person.push(
			root.paper.rect(85.84, 93.21, 56.55, 30.4, 10, 10).attr('fill', '#ffffff'),
			root.paper.circle(74.95, 104.37, 9.01).attr('fill', root.colors[1])
		);
		root.set_bed = root.paper.set();
		root.set_bed.push(
			root.paper.rect(55.64, 82.07, 8.01, 62.93, 4, 4),
			root.paper.rect(56.57, 115.18, 85.32, 11.01, 5.5, 5.5),
			root.paper.rect(134.39, 88.83, 8.01, 56.17, 4, 4)

		);

		root.roundcircle = root.paper.circle(99.23, 62.42, 15.42).attr('fill', '#ffffff');
		root.set_plus = root.paper.set();
		root.set_plus.push(
			root.paper.rect(97.71, 54.61, 3.04, 15.63, 1.37, 1.37),
			root.paper.rect(97.71, 54.61, 3.04, 15.63, 1.37, 1.37).transform('r90')
		);

		root.set_person.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});
		root.set_bed.attr({
			"stroke-width" : 0,
			"fill" : root.colors[0],
			"opacity" : 0
		});
		root.roundcircle.attr({
			"stroke-width" : 0,
			"opacity" : 0
		});
		root.set_plus.attr({
			"stroke-width" : 0,
			"fill" : root.colors[2],
			"opacity" : 0
		});



		root.ellipseAni = Raphael.animation({
			'opacity' : .05
		}, 1000);
		root.opacityAni = Raphael.animation({
			'opacity' : 1
		}, 1000);

		root.ellipse.animate(root.ellipseAni.delay(0));

		root.set_bed.animate(root.opacityAni.delay(1000));
		root.set_person.animate(root.opacityAni.delay(1500));
		root.roundcircle.animate(root.opacityAni.delay(1800));
		root.set_plus.animate(root.opacityAni.delay(2000));

		root.makeHTML();

	}



	this.makeHTML = function(){
		if ( !$(root.obj).next().hasClass('roundBox') ) return false;

		var outputHTML = '';
		outputHTML += '<ul class="'+root.datas['type']+'" style="display:none;">'
		for ( key in root.datas['field'] ){
			//var color = "color:"+root.colors[0]+";";
			var color = "color:#000;";
			var bgcolor = "background-color:"+root.colors[0]+";";
			var data = root.datas['field'][key];
            var dataDisplay = data;

			if (!data){
			 	data = 'null';
             //    dataDisplay = "N/A";
			}else{
			 	data = data;
             //    if (data == 0.00){
             //        dataDisplay = "N/A";
             //    }
             //    else {
             //        dataDisplay = data;
             //    }
			}



			outputHTML += '<li>';
			outputHTML += '<div class="table-row">';
			//outputHTML += '<div class="cell first"><i class="bullet" style="'+bgcolor+'"></i></div>';
			outputHTML += '<div class="cell"><strong class="title">'+key+'</strong></div>';
			// outputHTML += '<div class="cell"><span class="value" style="'+color+'">'+data+'</span></div>';
			outputHTML += '<div class="cell"><span class="value">'+dataDisplay+'</span></div>';
			outputHTML += '</div>';
			outputHTML += '</li>';
		}
		outputHTML += '</ul>\
		<span class="additional-text text-small"><i>' + _messages.pressureInjuriesDescription + '</i></span>';
		$(root.obj).next().html(outputHTML);
		$(root.obj).next().find('ul').delay(500).slideDown(800,function(){
			equalheight('.equalsRow .graph-container');
		});

	}
	this.makeHTML();
}

function draw_Infections(objId){
	var root = this;

	this.obj = $(objId).get(0);
	this.datas = $(this.obj).data();


	this.colors = [
		'#c771f3',
		'#cd88f0',
		'#e0a4ff'
	];

	this.setting = function(data){
		if ( $(root.obj).find('svg').length != 0 ){
			$(root.obj).empty();
		}
		root.data = [];
		root.datas['field'] = data;
		for ( key in root.datas['field'] ){
			root.data.push( parseFloat(root.datas['field'][key]) );
		}

		root.max = root.data.length;

		root.viewBoxW = 200 ;
		root.viewBoxH = 200;

		root.paper = null;
		root.paper = Raphael(root.obj);
		root.paper.canvas.setAttribute("id", "svgInfections");
		root.paper.setViewBox(0,0,root.viewBoxW,root.viewBoxH, true);
		//root.paper.canvas.setAttribute('preserveAspectRatio', 'none');
		root.paper.canvas.removeAttribute('width');
		root.paper.canvas.removeAttribute('height');
		root.paper.canvas.removeAttribute('style');

		root.maxData = Math.max.apply(null, root.data);

		root.draw();
	}

	this.getScale = function(i){
		var value = parseFloat(root.data[i]/root.maxData);
		if ( value < 1 && value > 0.5 ){
			value = 0.8;
		}else if( value < 0.5 ){
			value = 0.6;
		}
		var scale = "s" + parseFloat( value );
		return scale;
	}

	this.draw = function(){
		// root.text = root.paper.text(root.viewBoxW/2, root.viewBoxH-25, _messages.infectionsDescription);
		root.text = root.paper.text(root.viewBoxW/2, root.viewBoxH-25, '');
		root.text.attr({
			'opacity' : 0,
			'font-size' : 10
		});

		root.ellipse = root.paper.ellipse(100.5, 150, 44.5, 11);
		root.ellipse.attr({
			'opacity' : 0,
			'fill' : '#000'
		});

		root.outcircle = root.paper.circle(100.12, 99.79, 51.79);
		root.outcircle.attr({
			'opacity' : 0,
			'stroke-width' : 0,
			'fill' : root.colors[0]
		});
		root.incircle = root.paper.circle(100.12, 99.79, 44.46);
		root.incircle.attr({
			'opacity' : 0,
			'stroke-width' : 0,
			'fill' : '#ffffff'
		});
		root.circles_set = root.paper.set();
		root.circles_set.push(
			root.outcircle,
			root.incircle
		);

		root.params = [
			{
				'opacity' : 0,
				'stroke-width' : 0,
				'fill' : root.colors[0]
			},
			{
				'opacity' : 0,
				'stroke-width' : 0,
				'fill' : root.colors[1]
			},
			{
				'opacity' : 0,
				'stroke-width' : 0,
				'fill' : root.colors[2]
			}
		];
		root.paths = [];
		root.pathsData = [];
		root.pathsData = [
			'M92,124.48l-11.1,3.85A2,2,0,0,1,79,128l-9-7.25a2,2,0,0,1-.71-1.92l2-11a2,2,0,0,1,1.31-1.52l11.1-3.85a2,2,0,0,1,1.91.33l9,7.25A2,2,0,0,1,95.4,112l-2,11A2,2,0,0,1,92,124.48Z',
			'M123,86.48l-11.1,3.85A2,2,0,0,1,110,90l-9-7.25a2,2,0,0,1-.71-1.92l2-11a2,2,0,0,1,1.31-1.52l11.1-3.85a2,2,0,0,1,1.91.33l9,7.25A2,2,0,0,1,126.4,74l-2,11A2,2,0,0,1,123,86.48Z',
			'M132,109.48l-11.1,3.85A2,2,0,0,1,119,113l-9-7.25a2,2,0,0,1-.71-1.92l2-11a2,2,0,0,1,1.31-1.52l11.1-3.85a2,2,0,0,1,1.91.33l9,7.25A2,2,0,0,1,135.4,97l-2,11A2,2,0,0,1,132,109.48Z'
		];
		root.scales = [];
		root.paths_set = root.paper.set();

		for (var i = 0,max = root.max; i < max ; i++ ){
			root.paths[i] = root.paper.path(root.pathsData[i]);
			root.paths[i].attr(root.params[i]);
			root.paths[i].transform(root.getScale(i));
			root.paths_set.push(root.paths[i]);
		}

		root.ellipseAni = Raphael.animation({
			'opacity' : .05
		}, 1000);
		root.circlesAni = Raphael.animation({
			'opacity' : 1
		}, 1000);
		root.pathsAni = Raphael.animation({
			'opacity' : 1
		}, 1000);

		root.ellipse.animate(root.ellipseAni.delay(0));
		root.circles_set.animate(root.circlesAni.delay(1000));
		root.paths_set.animate(root.pathsAni.delay(1500));
		root.text.animate(root.pathsAni.delay(1800));

		root.makeHTML();
	}

	this.makeHTML = function(){
		if ( !$(root.obj).next().hasClass('roundBox') ) return false;

		var outputHTML = '';
		outputHTML += '<span class="additional-text color-infections"><strong>'
			+ _messages.infectionsSubtitle + '</strong></span>\
		<ul class="'+root.datas['type']+'" style="display:none;">';
		var cnt = 0;
		for ( key in root.datas['field'] ){
			//var color = "color:"+root.colors[cnt]+";";
			var color = "color:#000;";
			var bgcolor = "background-color:"+root.colors[cnt]+";";
			var data = root.datas['field'][key];
			var dataDisplay = data;
			if (!data){
				data = 'null';
//                displayData = "N/A"
			}else{
				data = data;
/*
                if (data == 0.00){
                    dataDisplay = "N/A";
                }
                else {
                    dataDisplay = data;
                }
*/
			}

			outputHTML += '<li>';
			outputHTML += '<div class="table-row">';
			outputHTML += '<div class="cell first"><i class="bullet" style="'+bgcolor+'"></i></div>';
			outputHTML += '<div class="cell"><strong class="title">'+key+'</strong></div>';
			// outputHTML += '<div class="cell"><span class="value" style="'+color+'">';
			outputHTML += '<div class="cell"><span class="value">';
			if (cnt == 0){
				if (root.maxData == root.data[cnt]){
					outputHTML += "< ";
				}else if( root.maxData > root.data[cnt] ){
					outputHTML += "> ";
				}
			}
			outputHTML += dataDisplay + '</span></div>'
			outputHTML += '</div>';
			outputHTML += '</li>';


			cnt++;
		}
		outputHTML += '</ul>\
		<span class="additional-text text-small"><i>' + _messages.infectionsDescription + '</i></span>';
		$(root.obj).next().html(outputHTML);
		$(root.obj).next().find('ul').delay(500).slideDown(800,function(){
			equalheight('.equalsRow .graph-container');
		});

	}
}

function draw_smile(objId){

	var root = this;
	this.obj = $(objId).get(0);
	this.size = [200,200];
	this.num = 0;
	this.datas = $(this.obj).data();

	this.colors = [
		'#fcd12a',
		'#ffe35a',
		'#d8ae5d'
	];

	this.setting = function(data){
		if ( $(root.obj).find('svg').length != 0 ){
			$(root.obj).empty();
		}
		root.data = [];
		root.datas['field'] = data;
		for ( key in root.datas['field'] ){
			root.data.push( parseFloat(root.datas['field'][key]) );
		}

		/*
		root.data = [
			10,
			50,
			80
		];
		*/

		root.max = root.data.length;

		root.maxData = Math.max.apply(null, root.data);

		root.pos = root.size[0]/2;
		root.viewBoxW = 600;
		root.viewBoxH = 200;

		root.paper = null;
		root.paper = Raphael(root.obj);
		root.paper.canvas.setAttribute("id", "svgSmile");
		root.paper.setViewBox(0,0,root.viewBoxW,root.viewBoxH, true);
		//root.paper.canvas.setAttribute('preserveAspectRatio', 'none');
		root.paper.canvas.removeAttribute('width');
		root.paper.canvas.removeAttribute('height');
		root.paper.canvas.removeAttribute('style');

		root.draw();
	}


	this.get_percent_value = function (min, max, v){
		var r = Math.ceil(max - min) * (v/100);
		return r;
	}

	this.getPosition = function(n){
		var p = [];
		if (root.max == 1){
			p.x = root.viewBoxW/2 - root.size[0]/2;
		}else if(root.max == 2){
			if (n == 0){
				p.x = root.viewBoxW/2 - root.size[0];
			}else{
				p.x = root.viewBoxW/root.max;
			}
		}else{
			if (n == 0){
				p.x = 0;
			}else if(n == 1){
				p.x = root.viewBoxW/2 - root.size[0]/2;
			}else{
				p.x = root.viewBoxW - root.size[0];
			}
		}
		return p;
	}
	this.getScale = function(i){
		var value = parseFloat(root.data[i]/root.maxData);
		if (value != 1){
			if (value < 1 && value > 0.8){
				value = 0.9+(value/10);
			}else if(value > 0.6){
				value = 0.8+(value/10);
			}else if(value > 0.4){
				value = 0.7+(value/10);
			}else if(value > 0.2){
				value = 0.6+(value/10);
			}else{
				value = 0.5+(value/10);
			}
		}

		//var scale = "s" + parseFloat( value );
		var scale = parseFloat( value );
		return scale;
	}

	this.drawSmile = function(){
		var local = this;
		local.scale = root.getScale(root.num);
        local.scale = 1
		local.pos = root.getPosition(root.num);


		if (root.num == 0){
			local.image = root.paper.image( '/imgs/performance-data/recommendation-benchmark.svg', local.pos.x,0, root.viewBoxH,root.viewBoxH);
		}else if(root.num == 1){
			local.image = root.paper.image( '/imgs/performance-data/recommendation-average.svg', local.pos.x,0, root.viewBoxH,root.viewBoxH);
		}else if(root.num == 2){
			local.image = root.paper.image( '/imgs/performance-data/recommendation-hospital.svg', local.pos.x,0, root.viewBoxH,root.viewBoxH);
		}


		local.g = root.paper.set();

		local.g.push(
			local.image
		);

		local.g.attr({
			opacity : 0,
			'transform' : 's0'
		});

		var ani = Raphael.animation({
			opacity: 1,
			'transform' : 's'+local.scale
		},1500,'backIn');
		local.g.animate(ani.delay( 300+(100*root.num) ));

		root.num++;

		root.makeHTML();
	}

	this.draw = function(){
		var o = [];
		for (var i = 0, max = root.max ; i < max ; i++ ){
			o[i] = new root.drawSmile();
		}
	}

	this.makeHTML = function(){
		if ( !$(root.obj).next().hasClass('roundBox') ) return false;

		var outputHTML = '';
		if (root.datas['size'] == 'big'){
			$(root.obj).next('.roundBox').addClass('big');
			$(root.obj).next('.roundBox').addClass('col-'+root.max);
		}
		outputHTML += '<ul class="' + root.datas['type'] + '" style="display:none;">'
		var cnt = 0;
		for ( key in root.datas['field'] ){
			//var color = "color:"+root.colors[cnt]+";";
			var color = "color:#000;";
			var bgcolor = "background-color:"+root.colors[cnt]+";";
			var data = root.datas['field'][key];
			var dataDisplay = data;
			if (!data){
				data = 'null';
//                dataDisplay = "N/A";
			}else{
				data = data+"%";
/*
				if (data == "0.0%") {
                    dataDisplay = "N/A";
                }
                else {
                    dataDisplay = data;
                }
*/
			}

			outputHTML += '<li>';
			outputHTML += '<div class="table-row">';
			outputHTML += '<div class="cell first"><i class="bullet" style="'+bgcolor+'"></i></div>';
			outputHTML += '<div class="cell"><strong class="title">'+key+'</strong></div>';
			// outputHTML += '<div class="cell"><span class="value" style="'+color+'">'+data+'</span></div>';
			outputHTML += '<div class="cell"><span class="value">'+dataDisplay+'</span></div>';
			outputHTML += '</div>';
			outputHTML += '</li>';
			cnt++;
		}
		outputHTML += '</ul>';
		$(root.obj).next().html(outputHTML);
		$(root.obj).next().find('ul').delay(500).slideDown(800,function(){
			equalheight('.equalsRow .graph-container');
		});

	}
	this.makeHTML();
}

function draw_rate(objId){
	var root = this;

	this.obj = $(objId).get(0);
	this.datas = $(this.obj).data();


	this.colors = [
		'#c771f3',
		'#e0a4ff',
		'#ccf2e8'
	];



	this.updateVal = function(value, total, R, hand, N) {
		ani = Raphael.animation({arc: [value, total, R, N]}, 2000, "backOut");
		hand.animate(ani.delay(500+(N*250)));
	}

	this.setting = function(data){

		if ( $(root.obj).find('svg').length != 0 ){
			$(root.obj).empty();
		}
		root.data = [];
		root.datas['field'] = data;

		for ( key in root.datas['field'] ){
			root.data.push( parseFloat(root.datas['field'][key]) );
		}


		root.max = root.data.length;

		root.viewBoxW = 400 ;
		root.viewBoxH = 400;
		root.R = [
			100,
			80,
			60
		];
		root.startPos = 200;

		root.paper = null;
		root.paper = Raphael(root.obj);
		root.paper.canvas.setAttribute("id", "svgRate");
		root.paper.setViewBox(0,0,root.viewBoxW,root.viewBoxH, true);
		//root.paper.canvas.setAttribute('preserveAspectRatio', 'none');
		root.paper.canvas.removeAttribute('width');
		root.paper.canvas.removeAttribute('height');
		root.paper.canvas.removeAttribute('style');

		root.paper.customAttributes.arc = function (value, total, R, N) {
			var alpha = 360 / total * value,
				a = (90 - alpha) * Math.PI / 180,
				x = root.startPos + R * Math.cos(a),
				y = root.startPos - R * Math.sin(a),
				path;
			if (total == value) {
				path = [["M", root.startPos, root.startPos - R], ["A", R, R, 0, 1, 1, parseFloat(root.startPos-0.01), root.startPos - R]];
			} else {
				path = [["M", root.startPos, root.startPos - R], ["A", R, R, 0, +(alpha > 180), 1, x, y]];
			}

			root.circle[N].show();
			root.circle[N].attr({
				cx : x,
				cy : y
			});

			return {path: path};
		};

		root.draw();
	}

	this.makeHTML = function(){
		if ( !$(root.obj).next().hasClass('roundBox') ) return false;

		var outputHTML = '';
		outputHTML += '<span class="additional-text color-rate"><strong>'
			+ _messages.overallRatingSubtitle + '</strong></span>\
			<ul class="'+root.datas['type']+'" style="display:none;">'
		var cnt = 0;
		for ( key in root.datas['field'] ){
			//var color = "color:"+root.colors[cnt]+";";
			var color = "color:#000;";
			var bgcolor = "background-color:"+root.colors[cnt]+";";
			var data = root.datas['field'][key];
			if (!data){
				data = 'null';
			}else{
			    if (data == "N/A")
                {
                    data = data;
                }
                else {
                    data = data+"%";
                }

			}
			outputHTML += '<li>';
			outputHTML += '<div class="table-row">';
			outputHTML += '<div class="cell first"><i class="bullet" style="'+bgcolor+'"></i></div>';
			outputHTML += '<div class="cell"><strong class="title">'+key+'</strong></div>';
			// outputHTML += '<div class="cell"><span class="value" style="'+color+'">'+data+'</span></div>';
			outputHTML += '<div class="cell"><span class="value">'+data+'</span></div>';
			outputHTML += '</div>';
			outputHTML += '</li>';
			cnt++;
		}
		outputHTML += '</ul>';
		$(root.obj).next().html(outputHTML);
		$(root.obj).next().find('ul').delay(500).slideDown(800,function(){
			equalheight('.equalsRow .graph-container');
		});
	}

	this.draw = function(){
		var ellipse = root.paper.ellipse(200, 310, 54, 10);
		ellipse.attr({
			'opacity' : 0,
			'fill' : '#000'
		});
		root.circleFirst = [];
		root.circle = [];
		param = [
			{
				stroke: root.colors[0],
				"stroke-width": 20
			},
			{
				stroke: root.colors[1],
				"stroke-width": 20
			},
			{
				stroke: root.colors[2],
				"stroke-width": 20
			}
		];


		var ellipseAni = Raphael.animation({
			'opacity' : .05
		}, 1000);
		ellipse.animate(ellipseAni.delay(0));


		for (var i = 0, max=root.max;i < max ; i++ ){
			root.circle[i] = root.paper.circle(200, root.R[i], 5).attr({ 'stroke' : root.colors[i], 'width' : 0, 'height' : 0, 'stroke-width' : 10 });
			root.circleFirst[i] = root.paper.circle(200, (200-root.R[i]), 5).attr({ 'stroke' : root.colors[i], 'width' : 0, 'height' : 0, 'stroke-width' : 10 });
			//root.circle[i].hide();
			root.sec = root.paper.path().attr(param[i]).attr({arc: [0, 100, root.R[i], i]});
			var updateData = (root.data[i]) ? root.data[i] : 0;
			root.updateVal(updateData, 100, root.R[i], root.sec, i);
		}

		root.makeHTML();
	}

	//this.makeHTML();
}

function draw_bottle(objId){
	var root = this;
	this.obj = $(objId).get(0);
	this.size = [200,200];
	this.num = 0;
	this.datas = $(this.obj).data();

	this.colors = [
		'#25bff0',
		'#80dbf8',
		'#a3e9ff'
	];

	this.image_path = [
		'/imgs/performance-data/bottle-benchmark.svg',
		'/imgs/performance-data/bottle-average.svg',
		'/imgs/performance-data/bottle-hospital.svg'
	];

	this.setting = function(data){
		if ( $(root.obj).find('svg').length != 0 ){
			$(root.obj).empty();
		}
		root.data = [];
		root.datas['field'] = data;
		for ( key in root.datas['field'] ){
			root.data.push( parseFloat(root.datas['field'][key]) );
		}
		root.max = root.data.length;

		root.pos = root.size[0]/2;

		// if (root.datas['size']){
		// 	root.viewBoxW = 600;
		// }else{
			root.viewBoxW = (root.max >= 2) ? 300 : 200;
		// }

		root.viewBoxH = 200;

		root.paper = null;
		root.paper = Raphael(root.obj);
		root.paper.canvas.setAttribute("id", "svgBottle");
		root.paper.setViewBox(0,0,root.viewBoxW,root.viewBoxH, true);
		//root.paper.canvas.setAttribute('preserveAspectRatio', 'none');
		root.paper.canvas.removeAttribute('width');
		root.paper.canvas.removeAttribute('height');
		root.paper.canvas.removeAttribute('style');

		root.draw();

	}



	this.get_percent_value = function (min, max, v){
		var r = Math.ceil(max - min) * (v/100);
		return r;
	}



	this.drawBottle = function(){
		var local = this;

		var pos = root.getPosition(root.num);
		local.image = root.paper.image( root.image_path[root.num], pos,0, root.viewBoxH,root.viewBoxH);
		local.image.attr({
			opacity : 0
		});

		local.rect = root.paper.rect(pos+74.7, 0, 51.5, 0).attr({
			'fill' : root.colors[root.num],
			'stroke-width' : 0
		});
		var aniR = 0;
		local.rect.attr({
			'r' : aniR,
			'y' : 137.5,
			'height' : 0,
			'transform': "r180"
		});
		// local.imageMask = root.paper.image( '/img/bottle-mask.png', pos,0, root.viewBoxH,root.viewBoxH);
		// local.imageMask.attr({
		// 	opacity : 0
		// });
		local.g = root.paper.set();
		local.g.push(
			local.image,
			local.rect
			// local.imageMask
		);
		if (!root.data[root.num]){
			root.data[root.num] = 0;
		}
		var aniH = root.get_percent_value(0, 61, root.data[root.num]);
		var bottleAni = Raphael.animation({
			opacity: 1
		}, 1000);
		var rectAni = Raphael.animation({
			height : aniH
		}, 2500, '<>');

		local.image.animate(bottleAni);

		local.rect.animate(rectAni.delay(1200));

		root.num++;
	}
	this.getPosition = function(n){
		var p;
		if (root.max == 1){
			p = ( root.viewBoxW/2) - root.size[0]/2; ;
		}else if (root.max == 2){
			// if (root.datas['size'] == 'big'){
			// 	if (n == 0){
			// 		p = (root.viewBoxW - root.viewBoxH) / root.max - (root.viewBoxH/2);
			// 	}else{
			// 		p = (root.viewBoxW - root.viewBoxH) / root.max + (root.viewBoxH/2);
			// 	}
			// }else{
				if (n == 0){
					p = 0;
				}else{
					p = root.viewBoxW - root.size[0];
				}
			// }

		}else{
			// if (root.datas['size'] == 'big'){
			// 	if ( n == 0 ){
			// 		p = ( root.viewBoxW/3 ) - root.size[0];
			// 	}else if(n == 1){
			// 		p = ( root.viewBoxW/ 2) - root.size[0]/2;
			// 	}else{
			// 		p = ( root.viewBoxW ) - root.size[0];
			// 	}
			// }else{
				if (n == 0){
					p = 0 - root.size[0]/4;
				}else if (n == 1){
					p = ( root.viewBoxW/2) - root.size[0]/2;
				}else{
					p = ( root.viewBoxW/2);
				}
			// }


		}
		return p;
	}

	this.draw = function(){
		var o = [];
		for (var i = 0, max = root.max ; i < max ; i++ ){
			o[i] = new root.drawBottle();
		}

		root.makeHTML();
	}



	this.makeHTML = function(){
		if ( !$(root.obj).next().hasClass('roundBox') ) return false;

		var outputHTML = '';
		if (root.datas['size'] == 'big'){
			$(root.obj).next('.roundBox').addClass('big');
			$(root.obj).next('.roundBox').addClass('col-'+root.max);
		}
		outputHTML += '<ul class="' + root.datas['type'] + '" style="display:none;">'
		var cnt = 0;
		for ( key in root.datas['field'] ){
			//var color = "color:"+root.colors[cnt]+";";
			var color = "color:#000;";
			var bgcolor = "background-color:"+root.colors[cnt]+";";
			var data = root.datas['field'][key];
			if (!data){
				data = 'null';
			}else{

			    if (data != "N/A") {
                    data = data + "%";
                }
			}

			outputHTML += '<li>';
			outputHTML += '<div class="table-row">';
			outputHTML += '<div class="cell first"><i class="bullet" style="'+bgcolor+'"></i></div>';
			outputHTML += '<div class="cell"><strong class="title">'+key+'</strong></div>';
			// outputHTML += '<div class="cell"><span class="value" style="'+color+'">'+data+'</span></div>';
			outputHTML += '<div class="cell"><span class="value">'+data+'</span></div>';
			outputHTML += '</div>';
			outputHTML += '</li>';
			cnt++;
		}
		outputHTML += '</ul>';
		$(root.obj).next().html(outputHTML);
		$(root.obj).next().find('ul').delay(500).slideDown(800,function(){
			equalheight('.equalsRow .graph-container');
		});

	}
}

$(function(){
	$(".collapse-area").each(function(idx){
		Fn_collapse(this);
	});
});

function Fn_collapse(o){
	var root = this;
	this.obj = $(o);
	this.$header = this.obj.find('.collapse-header');
	this.$togArea = this.obj.find('.collapse-target');


	this.$header.each(function(idx){
		$(this).click(function(e){
			e.preventDefault();
			if ( $(this).hasClass("active") ){
				root.closeTarget(this);
			}else{
				root.openTarget(this);
			}

		});
		$(this).find('.collapse-close').click(function(e){
			e.preventDefault();
			root.closeTarget(this);
		});

	});

	this.openTarget = function(o){
		$(o).addClass('active');
		$(o).next('.collapse-target').slideDown('normal', function(){
			$(this).addClass('active');
		});
	}
	this.closeTarget = function(o){
		$(o).removeClass('active');
		$(o).next('.collapse-target').slideUp('normal',function(){
			$(this).removeClass("active");
		});
	}
};



var _data = null;
var _config = {
	fixedNumber: 1,
	fixedNumber10K: 2,
}

function getAverageInfos(indexName){

	// MARK: modified by Andy with new change request (6/Sep/2017)
    //debugger;
	return getRangeAverageInfos(indexName);

	//if (!indexName) return;
	// var reValue;
	// $.each( _data.averageInfos, function( index, item ) {
	// 	if (index == "svha"){
	// 		switch(indexName){
	// 			case "rate" :
	// 				reValue = item.PatientSurveyRatingCareRate;
	// 				break;
	// 			case "smile" :
	// 				reValue = item.PatientSurveyWillingnessRecommendRate;
	// 				break;
	// 			case "bottle" :
	// 				reValue = item.HandHygieneRate;
	// 				break;
	// 			case "infections" :
	// 				reValue = item.SABRate;
	// 				break;
	// 			case "Injuries" :
	// 				reValue = item.Stage4PressureInjuriesRate;
	// 				break;
	// 			case "FallsWithHarm" :
	// 				reValue = item.FallsFractureClosedHeadInjuryRate;
	// 				break;
	// 			case "FallsWithDeath" :
	// 				reValue = item.FallsResultingDeathRate;
	// 				break;
	// 			case "MedicationDeaths" :
	// 				reValue = item.MedicationErrorResultingDeathRate;
	// 				break;
	// 		}
	// 	}

	// });

	// return reValue.toFixed(_config.fixedNumber);;
}

// MARK: added by Andy with new change request (5/Sep/2017)
function getRangeAverageInfos(indexName){
	//if (!indexName) return;
	var svhaGroupInfo = _data.hospitalInfos[0];
	var periodInfos = _data.periodInfos;
	if (svhaGroupInfo.name !== "SVHA Group") {
		//console.log('First item on hospitalInfos(' + svhaGroupInfo.name + ') is not "SVHA Group"');
		return null;
	}

	var reValue = null;
	$.each( _data.averageInfos, function( index, item ) {
		if (index == "svha"){
			switch(indexName){
				case "rate" :
					if (periodInfos.PatientSurveyRatingCare != undefined) {
						var start = periodInfos.PatientSurveyRatingCare.start;
						var end = periodInfos.PatientSurveyRatingCare.end;
						reValue = _helper.getPatientSurveyCareRangeRate(svhaGroupInfo, start, end);
					}
					break;
				case "smile" :
					if (periodInfos.PatientSurveyWillingnessRecommend != undefined) {
						var start = periodInfos.PatientSurveyWillingnessRecommend.start;
						var end = periodInfos.PatientSurveyWillingnessRecommend.end;
						reValue = _helper.getPatientWillingnessRecommendRangeRate(svhaGroupInfo, start, end);
					}
					break;
				case "bottle" :
					if (periodInfos.HandHygiene != undefined) {
						var start = periodInfos.HandHygiene.start;
						var end = periodInfos.HandHygiene.end;
						reValue = _helper.getHandHygieneRangeRate(svhaGroupInfo, start, end);
					}
					break;
				case "infections" :
					if (periodInfos.SAB != undefined) {
						var start = periodInfos.SAB.start;
						var end = periodInfos.SAB.end;
						reValue = _helper.getSABInfectionRangeRate(svhaGroupInfo, start, end);
					}
					break;
				case "Injuries" :

					if (periodInfos.Stage4PressureInjuries != undefined) {
						var start = periodInfos.Stage4PressureInjuries.start;
						var end = periodInfos.Stage4PressureInjuries.end;
						reValue = _helper.getStage4PressureInjuryRangeRate(svhaGroupInfo, start, end);
					}
					break;
				case "FallsWithHarm" :
					if (periodInfos.FallsFractureClosedHeadInjury != undefined) {
						var start = periodInfos.FallsFractureClosedHeadInjury.start;
						var end = periodInfos.FallsFractureClosedHeadInjury.end;
						reValue = _helper.getFallsFractureClosedHeadInjuryRangeRate(svhaGroupInfo, start, end);
					}
					break;
				case "FallsWithDeath" :
					if (periodInfos.FallsResultingDeath != undefined) {
						var start = periodInfos.FallsResultingDeath.start;
						var end = periodInfos.FallsResultingDeath.end;
						reValue = _helper.getFallsResultingDeathRangeRate(svhaGroupInfo, start, end);
					}
					break;
				case "MedicationDeaths" :
					if (periodInfos.MedicationErrorResultingDeath != undefined) {
						var start = periodInfos.MedicationErrorResultingDeath.start;
						var end = periodInfos.MedicationErrorResultingDeath.end;
						reValue = _helper.getMedicationErrorResultingDeathRangeRate(svhaGroupInfo, start, end);
					}
					break;
			}
		}
	});

	return reValue;
}

function getHospitalInfos(indexName){
	// MARK: modified by Andy with new change request (6/Sep/2017)
	return getHospitalRangeInfos(indexName);

	// var reValue;
	// $.each( _data.hospitalInfos, function( index, item ) {
	// 	if (item.name == hospitalName){
	// 		switch(indexName){
	// 			case "rate" :
	// 				reValue = _helper.getPatientSurveyCareRate(item);
	// 				break;
	// 			case "smile" :
	// 				reValue = _helper.getPatientWillingnessRecommendRate(item);
	// 				break;
	// 			case "bottle" :
	// 				console.log(_helper.getHandHygieneRangeRate(item, _data.periodInfos.HandHygiene.start, _data.periodInfos.HandHygiene.end));
	// 				reValue = _helper.getHandHygieneRate(item);
	// 				break;
	// 			case "infections" :
	// 				reValue = _helper.getSABInfectionRate(item);
	// 				break;
	// 			case "Injuries" :
	// 				reValue = _helper.getStage4PressureInjuryRate(item);
	// 				break;
	// 			case "FallsWithHarm" :
	// 				reValue = _helper.getFallsFractureClosedHeadInjuryRate(item);
	// 				break;
	// 			case "FallsWithDeath" :
	// 				reValue = _helper.getFallsResultingDeathRate(item);
	// 				break;
	// 			case "MedicationDeaths" :
	// 				reValue = _helper.getMedicationErrorResultingDeathRate(item);
	// 				break;
	// 		}
	// 	}

	// });
	// return reValue;

}

// MARK: added by Andy with new change request (6/Sep/2017)
function getHospitalRangeInfos(indexName){
	var periodInfos = _data.periodInfos;
	var reValue = null;

	$.each( _data.hospitalInfos, function( index, item ) {
		if (item.name == hospitalName){
			switch(indexName){
				case "rate" :
					if (periodInfos.PatientSurveyRatingCare != undefined) {
						var start = periodInfos.PatientSurveyRatingCare.start;
						var end = periodInfos.PatientSurveyRatingCare.end;
						reValue = _helper.getPatientSurveyCareRangeRate(item, start, end);
					} else {
						reValue = _helper.getPatientSurveyCareRate(item);
					}
					break;
				case "smile" :
					if (periodInfos.PatientSurveyWillingnessRecommend != undefined) {
						var start = periodInfos.PatientSurveyWillingnessRecommend.start;
						var end = periodInfos.PatientSurveyWillingnessRecommend.end;
						reValue = _helper.getPatientWillingnessRecommendRangeRate(item, start, end);
					} else {
						reValue = _helper.getPatientWillingnessRecommendRate(item);
					}
					break;
				case "bottle" :
					if (periodInfos.HandHygiene != undefined) {

                        //reValue = _helper.getAveragePercentRate(item.data.hh_Data);
						var start = periodInfos.HandHygiene.start;
						var end = periodInfos.HandHygiene.end;
                        //
						 reValue = _helper.getDataInRange(item.data.hh_Data, start, end);
					} else {
                        reValue = _helper.getAveragePercentRate(item.data.hh_Data);
						// reValue = _helper.getHandHygieneRate(item);
					}
					break;
				case "infections" :
					if (periodInfos.SAB != undefined) {
						var start = periodInfos.SAB.start;
						var end = periodInfos.SAB.end;
						reValue = _helper.getSABInfectionRangeRate(item, start, end);
					} else {
						reValue = _helper.getSABInfectionRate(item);
					}
					break;
				case "Injuries" :
					if (periodInfos.Stage4PressureInjuries != undefined) {
						var start = periodInfos.Stage4PressureInjuries.start;
						var end = periodInfos.Stage4PressureInjuries.end;
						reValue = _helper.getStage4PressureInjuryRangeRate(item, start, end);
					} else {
						reValue = _helper.getStage4PressureInjuryRate(item);
					}
					break;
				case "FallsWithHarm" :
					if (periodInfos.FallsFractureClosedHeadInjury != undefined) {
						var start = periodInfos.FallsFractureClosedHeadInjury.start;
						var end = periodInfos.FallsFractureClosedHeadInjury.end;
						reValue = _helper.getFallsFractureClosedHeadInjuryRangeRate(item, start, end);
					} else {
						reValue = _helper.getFallsFractureClosedHeadInjuryRate(item);
					}
					break;
				case "FallsWithDeath" :
					if (periodInfos.FallsResultingDeath != undefined) {
						var start = periodInfos.FallsResultingDeath.start;
						var end = periodInfos.FallsResultingDeath.end;
						reValue = _helper.getFallsResultingDeathRangeRate(item, start, end);
					} else {
						reValue = _helper.getFallsResultingDeathRate(item);
					}
					break;
				case "MedicationDeaths" :
					if (periodInfos.MedicationErrorResultingDeath != undefined) {
						var start = periodInfos.MedicationErrorResultingDeath.start;
						var end = periodInfos.MedicationErrorResultingDeath.end;
						reValue = _helper.getMedicationErrorResultingDeathRangeRate(item, start, end);
					} else {
						reValue = _helper.getMedicationErrorResultingDeathRate(item);
					}
					break;
			}
		}

	});
	return reValue;

}

function getTermData(type, type1 ,graphType){
	var reValue = [];

// console.log('loading data');
// console.log(_data);

	if (type == "averageInfos"){

// console.log('loading avg info data');
// console.log(_data[type]);

		$.each( _data[type], function( index, item ) {
			if (type1 == index){
				switch(graphType){
					case "rate" :
						reValue = item.PatientSurveyRatingCareRate;
						break;
					case "smile" :
						reValue = item.PatientSurveyWillingnessRecommendRate;
						break;
					case "bottle" :
						reValue = item.HandHygieneRate;
						break;
					case "infections" :
						reValue = item.SABRate;
						break;
					case "Injuries" :
						reValue = item.Stage4PressureInjuriesRate;
						break;
					case "FallsWithHarm" :
						reValue = item.FallsFractureClosedHeadInjuryRate;
						break;
					case "FallsWithDeath" :
						reValue = item.FallsResultingDeathRate;
						break;
					case "MedicationDeaths" :
						reValue = item.MedicationErrorResultingDeathRate;
						break;
				}
			}

		});
	}else if(type == "hospitalInfos"){

		$.each( _data[type], function( index, item ) {

			if (item.name == type1){

				switch(graphType){
					case "rate" :
						var patientSurveyCareRate = _helper.getPatientSurveyCareRate(item);
						if (patientSurveyCareRate != null) {
							reValue = _helper.getPatientSurveyCareDetail(item);
						}
						break;
					case "smile" :
						var patientWillingnessRecommendRate = _helper.getPatientWillingnessRecommendRate(item);
						if (patientWillingnessRecommendRate != null) {
							reValue = _helper.getPatientWillingnessRecommendDetail(item);
						}
						break;
					case "bottle" :
					// console.log('loading bottle item');
					// console.log(item);
						var handHygieneRate = _helper.getHandHygieneRate(item);
					// console.log(handHygieneRate);
						if (handHygieneRate != null) {
							reValue = _helper.getHandHygieneDetail(item);
							// console.log('return value');
							// console.log(reValue);
						}
						break;
					case "infections" :
						//console.log(item);
						var sabRate = _helper.getSABInfectionRate(item);
						if (sabRate != null) {
							reValue = _helper.getSABInfectionDetail(item);
						}
						break;
					case "Injuries" :
						var fallsFractureClosedHeadInjuryRate = _helper.getFallsFractureClosedHeadInjuryRate(item);
						if (fallsFractureClosedHeadInjuryRate != null) {
							//console.log('  - Falls Fracture Closed Head Injury Rate: ' + fallsFractureClosedHeadInjuryRate);
							reValue = _helper.getStage4PressureInjuryDetail(item);
						}
						break;
					case "FallsWithHarm" :
						var stage4PressureInjuryRate = _helper.getStage4PressureInjuryRate(item);
						if (stage4PressureInjuryRate != null) {
							//console.log('  - Stage 4 Pressure Injury Rate: ' + stage4PressureInjuryRate);
							reValue = _helper.getFallsFractureClosedHeadInjuryDetail(item);
						}
						break;
					case "FallsWithDeath" :
						var fallsResultingDeathRate = _helper.getFallsResultingDeathRate(item);
						if (fallsResultingDeathRate != null) {
							// console.log('  - Falls Resulting Death Rate: ' + fallsResultingDeathRate);
							reValue = _helper.getFallsResultingDeathDetail(item);
						}
						break;

					case "MedicationDeaths" :
						var medicationErrorResultingDeathRate = _helper.getMedicationErrorResultingDeathRate(item);
						if (medicationErrorResultingDeathRate != null) {
							// console.log('  - Medication Error Resulting Death Rate: ' + medicationErrorResultingDeathRate);
							reValue = _helper.getMedicationErrorResultingDeathDetail(item);
						}
						break;
				}
			}
		});
	}


	return reValue;

}


var hospitalName;
var SelectHospital;
var graphType;

function set_dropdown_view(){
	var opt = [];
	opt.division = [];
	opt.hospotal = [];
	opt.hospotal['Public Hospitals Division'] = [];
	opt.hospotal['Private Hospitals Division'] = [];
	opt.hospotal['Aged Care Division'] = [];
	output = '';

	$.each( _data.hospitalInfos, function( index, item ) {
		if (item.type == "division"){
			var tmp = getTermData('hospitalInfos', item.name, graphType);
			if (tmp.length != 0){
				opt.division.push(item.name);
			}
		}else if(item.type == "hospital"){
			if (item.category == "Public Hospitals Division"){
				var tmp = getTermData('hospitalInfos', item.name, graphType);
				if (tmp.length != 0){
					opt.hospotal['Public Hospitals Division'].push(item.name);
				}
			}else if(item.category == "Private Hospitals Division"){
				var tmp = getTermData('hospitalInfos', item.name, graphType);
				if (tmp.length != 0){
					opt.hospotal['Private Hospitals Division'].push(item.name);
				}
			}else if(item.category == "Aged Care Division"){
				var tmp = getTermData('hospitalInfos', item.name, graphType);
				if (tmp.length != 0){
					opt.hospotal['Aged Care Division'].push(item.name);
				}
			}
		}
	});

	$.each(opt.division, function(index, item){
		if (item != hospitalName){
			tmp = getTermData('hospitalInfos', item, graphType);
			if (tmp.length != 0){
				output += '<option value="'+item+'">'+item+'</option>';
			}
		}
	});

	if (opt.hospotal['Private Hospitals Division'].length != 0){
		output += "<optgroup label='Private Hospitals Division'>";
		$.each(opt.hospotal['Private Hospitals Division'], function(index, item){
			if (item != hospitalName){
				tmp = getTermData('hospitalInfos', item, graphType);
				if (tmp.length != 0){
					output += '<option value="'+item+'">'+item+'</option>';
				}
			}

		});
		output += "</optgroup>";
	}

	if (opt.hospotal['Public Hospitals Division'].length != 0){
		output += "<optgroup label='Public Hospitals Division'>";
		$.each(opt.hospotal['Public Hospitals Division'], function(index, item){
			if (item != hospitalName){
				tmp = getTermData('hospitalInfos', item, graphType);
				if (tmp.length != 0){
					output += '<option value="'+item+'">'+item+'</option>';
				}
			}
		});
		output += "</optgroup>";
	}

	// if (opt.hospotal['Aged Care Division'].length != 0){
	// 	output += "<optgroup label='Aged Care Division'>";
	// 	$.each(opt.hospotal['Aged Care Division'], function(index, item){
	// 		if (item != hospitalName){
	// 			tmp = getTermData('hospitalInfos', item, graphType);
	// 			if (tmp.length != 0){
	// 				output += '<option value="'+item+'">'+item+'</option>';
	// 			}
	// 		}
	// 	});
	// 	output += "</optgroup>";
	// }


	$('.c_dropdown').append(output);
	if ($('.c_dropdown option:selected').index() != 0){
		$('.c_dropdown option').eq(0).remove();
	}
}

// MARK: added by Andy for new change request
function set_messages() {
	if (_data.messageInfos != undefined) {
		_messages = _data.messageInfos;
	}
}

function set_dropdown(){
	var opt = [];
	opt.svhaGroup = [];
	opt.division = [];
	opt.hospotal = [];
	opt.hospotal['Public Hospitals Division'] = [];
	opt.hospotal['Private Hospitals Division'] = [];
	opt.hospotal['Aged Care Division'] = [];
	output = '';

	$.each( _data.hospitalInfos, function( index, item ) {
		if (item.type == "svha-group") {
			opt.svhaGroup.push(item.name);
		}
		else if (item.type == "division"){
			opt.division.push(item.name);
			//console.log("division : " + item.name);
		}
		else if(item.type == "hospital"){
			//console.log("hospital : " + item.name);
			//console.log(item.category);
			if (item.category == "Public Hospitals Division"){
				opt.hospotal['Public Hospitals Division'].push(item.name);
			}else if(item.category == "Private Hospitals Division"){
				opt.hospotal['Private Hospitals Division'].push(item.name);
			}else if(item.category == "Aged Care Division"){
				opt.hospotal['Aged Care Division'].push(item.name);
			}
		}
		//opts += "<option value='"+item.name+"' "+selected+">"+item.name+"</option>";
	});

	$.each(opt.svhaGroup, function(index, item){
		var selected = (item == hospitalName) ? "selected='selected'" : '';
		output += '<option value="+item+" '+selected+'>'+item+'</option>';
	});

	$.each(opt.division, function(index, item){
		var selected = (item == hospitalName) ? "selected='selected'" : '';
		output += '<option value="+item+" '+selected+'>'+item+'</option>';
	});

	output += "<optgroup label='Private Hospitals Division'>";
	$.each(opt.hospotal['Private Hospitals Division'], function(index, item){
		var selected = (item == hospitalName) ? "selected='selected'" : '';
		if (item != ''){
			output += '<option value="+item+" '+selected+'>'+item+'</option>';
		}
	});
	output += "</optgroup>";

	output += "<optgroup label='Public Hospitals Division'>";
	$.each(opt.hospotal['Public Hospitals Division'], function(index, item){
		var selected = (item == hospitalName) ? "selected='selected'" : '';
		if (item != ''){
			output += '<option value="+item+" '+selected+'>'+item+'</option>';
		}
	});
	output += "</optgroup>";

	// output += "<optgroup label='Aged Care Division'>";
	// $.each(opt.hospotal['Aged Care Division'], function(index, item){
	// 	var selected = (item == hospitalName) ? "selected='selected'" : '';
	// 	if (item != ''){
	// 		output += '<option value="+item+" '+selected+'>'+item+'</option>';
	// 	}
	// });
	// output += "</optgroup>";


	$('.c_dropdown').append(output);

	console.log(output);

	// if ($('.c_dropdown option:selected').index() != 0){
	// 	$('.c_dropdown option').eq(0).remove();
	// }
}

function show_list(t){
	//console.log(_data);
	//_data.averageInfos.national.HandHygieneRate
	//_data.averageInfos.national.SABRate
	if  (hospitalName == "SVHA Group") {
		hospitalName = '';
	}

	$('.svgchart').each(function(idx){
		datas = $(this).data();
		$(this).addClass(datas['type']);
		if ( datas['type'] == 'rate-svg'){
			rate_data = [];
			if (_data.averageInfos.national.PatientSurveyRatingCareRate != undefined){
				rate_data['National Benchmark'] = _data.averageInfos.national.PatientSurveyRatingCareRate.toFixed(_config.fixedNumber);
			}
			if (getAverageInfos('rate')){
				rate_data['SVHA Average'] = getAverageInfos('rate');
			}
			if (hospitalName && getHospitalInfos('rate')){
				rate_data[hospitalName] = getHospitalInfos('rate');
				$(this).parents('.graph-container').find('.button-container').delay(1000).fadeIn('slower');
			}else{
				$(this).parents('.graph-container').find('.button-container').fadeOut('fast');
			}
			var d_rate = new draw_rate($(this));
			d_rate.setting(rate_data);

			// MARK: added by Andy
			var topText = _data.periodInfos.PatientSurveyRatingCare.timePeriodDescription;
			$(this).prepend('<span class="svg-text-top-left">' + topText + '</span>');
			$(this).append('<span class="svg-text-bottom-center">' + _messages.overallRatingDescription + '</span>');
		}
		else if( datas['type'] == 'smile-svg' ){
			smile_data = [];
			if (_data.averageInfos.national.PatientSurveyWillingnessRecommendRate != undefined){
				smile_data['National Benchmark'] = _data.averageInfos.national.PatientSurveyWillingnessRecommendRate.toFixed(_config.fixedNumber);
			}
			if (getAverageInfos('smile')){
				smile_data['SVHA Average'] = getAverageInfos('smile');
			}
			if (hospitalName && getHospitalInfos('smile')){
				smile_data[hospitalName] = getHospitalInfos('smile');
				$(this).parents('.graph-container').find('.button-container').delay(1000).fadeIn('slower');
			}else{
				$(this).parents('.graph-container').find('.button-container').fadeOut('fast');
			}
			d_smile = new draw_smile($(this));
			d_smile.setting(smile_data);

			// MARK: added by Andy
			var topText = _data.periodInfos.PatientSurveyWillingnessRecommend.timePeriodDescription;
			$(this).prepend('<span class="svg-text-top-left relative-xs">' + topText + '</span>');
			$(this).append('<span class="svg-text-bottom-center relative-xs">' + _messages.recommendationDescription + '</span>');
		}
		else if( datas['type'] == 'bottle-svg' ){
			bottle_data = [];
			if (_data.averageInfos.national.HandHygieneRate != undefined){
				bottle_data['National Benchmark'] = _data.averageInfos.national.HandHygieneRate.toFixed(_config.fixedNumber);
			}
			if (getAverageInfos('bottle')){
				bottle_data['SVHA Average'] = getAverageInfos('bottle');
			}

			if (hospitalName && getHospitalInfos('bottle')){
				bottle_data[hospitalName] = getHospitalInfos('bottle');
				$(this).parents('.graph-container').find('.button-container').delay(1000).fadeIn('slower');
			}else{
				$(this).parents('.graph-container').find('.button-container').fadeOut('fast');
			}
			d_bottle = new draw_bottle($(this));
			d_bottle.setting(bottle_data);

			// MARK: added by Andy
			var topText = _data.periodInfos.HandHygiene.timePeriodDescription;
			$(this).prepend('<span class="svg-text-top-left">' + topText + '</span>');
			$(this).append('<span class="svg-text-bottom-center">' + _messages.handHygieneDescription + '</span>');
		}
		else if( datas['type'] == 'infections-svg' ){
			infections_data = [];
			if (_data.averageInfos.national.SABRate != undefined){
				infections_data['National Benchmark'] = _data.averageInfos.national.SABRate.toFixed(_config.fixedNumber);
			}
			if (getAverageInfos('infections')){
				infections_data['SVHA Average'] = getAverageInfos('infections');
			}
			if (hospitalName && getHospitalInfos('infections')){
				infections_data[hospitalName] = getHospitalInfos('infections');
				$(this).parents('.graph-container').find('.button-container').delay(1000).fadeIn('slower');
			}else{
				$(this).parents('.graph-container').find('.button-container').fadeOut('fast');
			}
			d_infections = new draw_Infections($(this));
			d_infections.setting(infections_data);

			// MARK: added by Andy
			var topText = _data.periodInfos.SAB.timePeriodDescription;
			$(this).prepend('<span class="svg-text-top-left">' + topText + '</span>');
			$(this).append('<span class="svg-text-bottom-center">' + _messages.infectionsDescription + '</span>');
		}
		else if( datas['type'] == 'Injuries-svg' ){
			injuries_data = [];
			if (_data.averageInfos.national.Stage4PressureInjuriesRate != undefined){
				injuries_data['Target'] = _data.averageInfos.national.Stage4PressureInjuriesRate.toFixed(_config.fixedNumber10K);
			}
			if (getAverageInfos('Injuries')){
				injuries_data['SVHA Average'] = getAverageInfos('Injuries');
			}

			if (hospitalName && getHospitalInfos('Injuries')){
				injuries_data[hospitalName] = getHospitalInfos('Injuries');
				$(this).parents('.graph-container').find('.button-container').delay(1000).fadeIn('slower');
			}else{
				$(this).parents('.graph-container').find('.button-container').fadeOut('fast');
			}
			d_injuries = new draw_Injuries($(this));
			d_injuries.setting(injuries_data);

			// MARK: added by Andy
			var topText = _data.periodInfos.Stage4PressureInjuries.timePeriodDescription;
			$(this).prepend('<span class="svg-text-top-left">' + topText + '</span>');
			$(this).append('<span class="svg-text-bottom-center">' + _messages.pressureInjuriesDescription + '</span>');
		}
		else if( datas['type'] == 'FallsWithHarm-svg' ){
			FallsWithHarm_data = [];
			if (_data.averageInfos.national.FallsFractureClosedHeadInjuryRate != undefined){
				FallsWithHarm_data['Target'] = _data.averageInfos.national.FallsFractureClosedHeadInjuryRate.toFixed(_config.fixedNumber10K);
			}
			if (getAverageInfos('FallsWithHarm')){
				FallsWithHarm_data['SVHA Average'] = getAverageInfos('FallsWithHarm');
			}
			if (hospitalName && getHospitalInfos('FallsWithHarm')){
				FallsWithHarm_data[hospitalName] = getHospitalInfos('FallsWithHarm');
				$(this).parents('.graph-container').find('.button-container').delay(1000).fadeIn('slower');
			}else{
				$(this).parents('.graph-container').find('.button-container').fadeOut('fast');
			}
			d_FallsWithHarm = new draw_FallsWithHarm($(this));
			d_FallsWithHarm.setting(FallsWithHarm_data);

			// MARK: added by Andy
			var topText = _data.periodInfos.FallsFractureClosedHeadInjury.timePeriodDescription;
			$(this).prepend('<span class="svg-text-top-left">' + topText + '</span>');
			$(this).append('<span class="svg-text-bottom-center">' + _messages.fallsHarmDescription + '</span>');
		}
		else if( datas['type'] == 'FallsWithDeath-svg' ){
			FallsWithDeath_data = [];
			if (_data.averageInfos.national.FallsResultingDeathRate != undefined){
				FallsWithDeath_data['Target'] = _data.averageInfos.national.FallsResultingDeathRate.toFixed(_config.fixedNumber10K);
			}
			if (getAverageInfos('FallsWithDeath')){
				FallsWithDeath_data['SVHA Average'] = getAverageInfos('FallsWithDeath');
			}
			if (hospitalName && getHospitalInfos('FallsWithDeath')){
				FallsWithDeath_data[hospitalName] = getHospitalInfos('FallsWithDeath');
				$(this).parents('.graph-container').find('.button-container').delay(1000).fadeIn('slower');
			}else{
				$(this).parents('.graph-container').find('.button-container').fadeOut('fast');
			}
			d_FallsWithDeath = new draw_FallsWithDeath($(this));
			d_FallsWithDeath.setting(FallsWithDeath_data);

			// MARK: added by Andy
			var topText = _data.periodInfos.FallsResultingDeath.timePeriodDescription;
			$(this).prepend('<span class="svg-text-top-left">' + topText + '</span>');
			$(this).append('<span class="svg-text-bottom-center">' + _messages.fallDeathDescription + '</span>');
		}
		else if( datas['type'] == 'MedicationDeaths-svg' ){
			MedicationDeaths_data = [];
			if (_data.averageInfos.national.MedicationErrorResultingDeathRate != undefined){
				MedicationDeaths_data['Target'] = _data.averageInfos.national.MedicationErrorResultingDeathRate.toFixed(_config.fixedNumber10K);
			}
			if (getAverageInfos('MedicationDeaths')){
				MedicationDeaths_data['SVHA Average'] = getAverageInfos('MedicationDeaths');
			}

			if (hospitalName && getHospitalInfos('MedicationDeaths')){
				MedicationDeaths_data[hospitalName] = getHospitalInfos('MedicationDeaths');
				$(this).parents('.graph-container').find('.button-container').delay(1000).fadeIn('slower');
			}else{
				$(this).parents('.graph-container').find('.button-container').fadeOut('fast');
			}
			d_MedicationDeaths = new draw_MedicationDeaths($(this));
			d_MedicationDeaths.setting(MedicationDeaths_data);

			// MARK: added by Andy
			var topText = _data.periodInfos.MedicationErrorResultingDeath.timePeriodDescription;
			$(this).prepend('<span class="svg-text-top-left">' + topText + '</span>');
			$(this).append('<span class="svg-text-bottom-center">' + _messages.medicationDeathDescription + '</span>');
		}
	});
}
var detailTitle;
function show_view(graphType){

	$('.svgchart').attr('data-type', graphType+"-svg");
	$('.svgchart').parents('.detail').addClass(graphType);
	view_data = [];

	switch(graphType) {
		case "rate" :
			detailTitle = "Overall Ratings";
			if (_data.averageInfos.national.PatientSurveyRatingCareRate != undefined){
				view_data['National Benchmark'] = _data.averageInfos.national.PatientSurveyRatingCareRate.toFixed(_config.fixedNumber);
			}
			if (getAverageInfos(graphType)){
				view_data['SVHA Average'] = getAverageInfos(graphType);
			}
			if (hospitalName && getHospitalInfos(graphType)){
				view_data[hospitalName] = getHospitalInfos(graphType);
			}
			var d_rate = new draw_rate($('.svgchart'));
			d_rate.setting(view_data);
			break;
		case "smile" :
			detailTitle = "Patient Recommendation";
			if (_data.averageInfos.national.PatientSurveyWillingnessRecommendRate != undefined){
				view_data['National Benchmark'] = _data.averageInfos.national.PatientSurveyWillingnessRecommendRate.toFixed(_config.fixedNumber);
			}
			if (getAverageInfos(graphType)){
				view_data['SVHA Average'] = getAverageInfos(graphType);
			}

			if (hospitalName && getHospitalInfos('smile')){
				view_data[hospitalName] = getHospitalInfos('smile');
			}
			d_smile = new draw_smile($('.svgchart'));
			d_smile.setting(view_data);
			break;
		case "bottle" :
			detailTitle = "Hand Hygiene";
			if (_data.averageInfos.national.HandHygieneRate != undefined){
				view_data['National Benchmark'] = _data.averageInfos.national.HandHygieneRate.toFixed(_config.fixedNumber);
				
			}
			if (getAverageInfos(graphType)){
				view_data['SVHA Average'] = getAverageInfos(graphType);
				
			}
			if (hospitalName && getHospitalInfos('bottle')){
				view_data[hospitalName] = getHospitalInfos('bottle');
			}
			d_bottle = new draw_bottle($('.svgchart'));
			d_bottle.setting(view_data);
			break;
		case "infections" :
			detailTitle = "Infections in Hospitals";
			if (_data.averageInfos.national.SABRate != undefined){
				view_data['National Benchmark'] = _data.averageInfos.national.SABRate.toFixed(_config.fixedNumber);
			}
			if (getAverageInfos(graphType)){
				view_data['SVHA Average'] = getAverageInfos(graphType);
			}
			if (hospitalName && getHospitalInfos('infections')){
				view_data[hospitalName] = getHospitalInfos('infections');
			}
			d_infections = new draw_Infections($('.svgchart'));
			d_infections.setting(view_data);
			break;
		case "Injuries" :
			detailTitle = "Pressure Injuries";

			if (_data.averageInfos.national.Stage4PressureInjuriesRate != undefined){
				view_data['Target'] = _data.averageInfos.national.Stage4PressureInjuriesRate.toFixed(_config.fixedNumber10K);
			}
			if (getAverageInfos(graphType)){
				view_data['SVHA Average'] = getAverageInfos(graphType);
			}


			if (hospitalName && getHospitalInfos('Injuries')){
				view_data[hospitalName] = getHospitalInfos('Injuries');
			}
			d_injuries = new draw_Injuries($('.svgchart'));
			d_injuries.setting(view_data);
			break;
		case "FallsWithHarm" :
			detailTitle = "Falls with Harm";
			if (_data.averageInfos.national.FallsFractureClosedHeadInjuryRate != undefined){
				view_data['Target'] = _data.averageInfos.national.FallsFractureClosedHeadInjuryRate.toFixed(_config.fixedNumber10K);
			}
			if (getAverageInfos(graphType)){
				view_data['SVHA Average'] = getAverageInfos(graphType);
			}
			if (hospitalName && getHospitalInfos('FallsWithHarm')){
				view_data[hospitalName] = getHospitalInfos('FallsWithHarm');
			}
			d_FallsWithHarm = new draw_FallsWithHarm($('.svgchart'));
			d_FallsWithHarm.setting(view_data);
			break;
		case "FallsWithDeath" :
			detailTitle = "Falls with Death";
			if (_data.averageInfos.national.FallsResultingDeathRate != undefined){
				view_data['Target'] = _data.averageInfos.national.FallsResultingDeathRate.toFixed(_config.fixedNumber10K);
			}
			if (getAverageInfos(graphType)){
				view_data['SVHA Average'] = getAverageInfos(graphType);
			}
			if (hospitalName && getHospitalInfos('FallsWithDeath')){
				view_data[hospitalName] = getHospitalInfos('FallsWithDeath');
			}
			d_FallsWithDeath = new draw_FallsWithDeath($('.svgchart'));
			d_FallsWithDeath.setting(view_data);
			break;
		case "MedicationDeaths" :
			detailTitle = "Medication Deaths";
			if (_data.averageInfos.national.MedicationErrorResultingDeathRate != undefined){
				view_data['Target'] = _data.averageInfos.national.MedicationErrorResultingDeathRate.toFixed(_config.fixedNumber10K);
			}
			if (getAverageInfos(graphType)){
				view_data['SVHA Average'] = getAverageInfos(graphType);
			}
			if (hospitalName && getHospitalInfos('MedicationDeaths')){
				view_data[hospitalName] = getHospitalInfos('MedicationDeaths');
			}
			d_MedicationDeaths = new draw_MedicationDeaths($('.svgchart'));
			d_MedicationDeaths.setting(view_data);
			break;
	}
	//console.log(hospitalName);
	$("#hospitalTitle").text(hospitalName);
	$("#detailTitle").text(detailTitle);
	show_chart();
}

function show_chart(hname,data){
	var categorys = [];
	var val1 = [];
	var val2 = [];
	var val3 = [];
	var val4 = [];
	var data4 = [];

    //console.log('hospital name is ' + hospitalName);
	var data1 = getTermData('averageInfos', 'national', graphType);
	var data2 = getTermData('hospitalInfos', 'SVHA Group', graphType);
	var data3 = getTermData('hospitalInfos', hospitalName, graphType);

	// console.log('svha1');
	// console.log(data1);
	//  console.log('svha2');
	//  console.log(data2);
	// console.log('svha3');
	// console.log(data3);

	//var max = data2.length;
	//var max = data3.length;

	/*
	Highcharts.theme = {
		colors: ['#ff5046', '#fcd12a', '#25bff0', '#c77ff3']
	};
	*/
	var colors = ['#25bff0', '#fcd12a', '#ff5046', '#c77ff3']



    var data3_mod = [];
    var data3_keys = [];

    for(var i = 0; i < data3.length; i++)
    {
        data3_keys.push(data3[i].term);
    }

    for(var i = 0; i < data2.length; i++)
    {
        if (data3_keys.indexOf(data2[i].term)>=0) {
            //data3_mod.push(data3[i])
            //debugger;
            var da = data3.filter(function (rate) { return rate.term == data2[i].term });
            data3_mod.push(da[0]);
        }
        else {
            data3_mod.push({term: data2[i].term, rate: 0})
        }
    }

    var data2_keys = [];
    var data2_mod = [];

    for(var i = 0; i < data2.length; i++)
    {
        data2_keys.push(data2[i].term);
    }

    //debugger;
    for(var i = 0; i < data3_mod.length; i++)
    {
        if (data2_keys.indexOf(data3_mod[i].term) >= 0) {
            var da = data2.filter(function (rate) { return rate.term == data3_mod[i].term });
            data2_mod.push(da[0]);
        }
        else {
            data2_mod.push({term: data3_mod[i].term, rate: 0})
        }
    }

    //debugger;

	val2['name'] = "SVHA Group";
	val2['data'] = [];
	val2['color'] = colors[1];
	$.each(data2_mod, function(index, item){
		if (item.rate != "NaN"){
			categorys.push(item.term);
			val2['data'].push([item.term, parseFloat(item.rate)]);
		}
	});


	val3['name'] = hospitalName;
	val3['data'] = [];
	val3['color'] = colors[0];
	$.each(data3_mod, function(index, item){
		if (item.rate != "NaN"){
			//categorys.push(item.term);
			val3['data'].push([item.term, parseFloat(item.rate)]);
		}
	});


	var max = data2_mod.length;
    if (data1){
        // bar
        val1['name'] = "National Benchmark";
        val1['data'] = [];
        for (var i = 0, cmax = max; i < max ; i++ ){
            val1['data'].push(data1);
        }
        val1['type'] = "spline"	;
        val1['color'] = colors[3];
        val1['marker'] = {
            "lineWidth": 2,
            "lineColor": colors[3],
            "fillColor": "white"
        };

    }

    //  console.log('val1');
    //  console.log(val1);
    // //
    //  console.log('val2');
    //  console.log(val2);
    // //
    //  console.log('val3');
    //  console.log(val3);

	//Assigning Chart

	Highcharts.setOptions(Highcharts.theme);

	var options = {
		chart: {
			renderTo: 'detailChart',
			defaultSeriesType: 'column'
		},
		title: {
			text: ''
		},
		credits: {
			enabled: false
		},
		xAxis: {
			categories: []
		},
		yAxis: {
			min: 0,
			max: 100,
			title: {
					text: 'Percentage'
			}
		},
		series: []
	};

	if (val3.name && val3.data.length != 0){
		options.series.push(val3);
	}
	if (val2.name && val2.data.length != 0){
		options.series.push(val2);
	}
	if (hname){

		data4 = data;
		val4['name'] = hname;
		val4['data'] = [];
		val4['color'] = colors[2];
		$.each(data4, function(index, item){
			if (item.rate != "NaN"){
				val4['data'].push(parseFloat(item.rate));
			}

            if (!categorys.indexOf(item.term) >= 0) {
                categorys.push(item.term);
            }
		});

		if (max < val4['data'].length)
        {
            max = val4['data'].length;
            if (data1){
                // bar
                val1['name'] = "National Benchmark";
                val1['data'] = [];
                for (var i = 0, cmax = max; i < max ; i++ ){
                    val1['data'].push(data1);
                }
                val1['type'] = "spline"	;
                val1['color'] = colors[3];
                val1['marker'] = {
                    "lineWidth": 2,
                    "lineColor": colors[3],
                    "fillColor": "white"
                };

            }
        }


		options.series.push(val4);
	}
	if (val1.name && val1.data.length != 0 ){
		options.series.push(val1);
	}

	options.xAxis.categories = categorys;

	var chart_max = 100;
	var chart_title = "Percentage";
	switch(graphType){
		case "rate" :
			break;
		case "smile" :
			break;
		case "bottle" :
			break;
		case "infections" :
			chart_max = 2;
			chart_title = "Percentage";
			break;
		case "Injuries" :
			val1['name'] = "Target";
			chart_max = 2;
			chart_title = "Rate Per 10K Beddays";
			break;
		case "FallsWithHarm" :
			val1['name'] = "Target";
			chart_max = 2;
			chart_title = "Rate Per 10K Beddays";
			break;
		case "FallsWithDeath" :
			val1['name'] = "Target";
			chart_max = 2;
			chart_title = "Rate Per 10K Beddays";
			break;
		case "MedicationDeaths" :
			val1['name'] = "Target";
			chart_max = 2;
			chart_title = "Rate Per 10K Beddays";
			break;
	}
	//options.xAxis.yAxis.min = ;
	options.yAxis.max = chart_max;
	options.yAxis.title.text = chart_title;

	var chart = new Highcharts.Chart(options);

	if (options.series.length == 0){
		$(".detailChart").remove();
	}
}

equalheight = function(container){
	var wW = $(window).width();


	var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
	 $(container).each(function() {

	   $el = $(this);
	   $($el).height('auto')
	   //topPostion = $el.position().top;
	   topPostion = $el.offset().top;

	   if (currentRowStart != topPostion) {
		 for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
		   rowDivs[currentDiv].height(currentTallest);
		 }
		 rowDivs.length = 0; // empty the array
		 currentRowStart = topPostion;
		 currentTallest = $el.height();
		 rowDivs.push($el);
	   } else {
		 rowDivs.push($el);
		 currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
	  }
	   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
		   if (wW < 990){
				rowDivs[currentDiv].height('auto');
		   }else{
				rowDivs[currentDiv].height(currentTallest);
		   }
	   }
 });
}

var _helper = {
	getHandHygieneRate: function(item) {
		// var numerator = item.data.hh_N;
		// var denominator = item.data.hh_D;
		// return this.getPercentRate(numerator, denominator);

		return this.getAveragePercentRate(item.data.hh_Data);
	},
	getHandHygieneRangeRate: function(item, start, end) {
		// var numerator = item.data.hh_N;
		// var denominator = item.data.hh_D;
		// return this.getPercentRangeRate(numerator, denominator, start, end);

        return this.getDataInRange(item.data.hh_Data, start, end)

        //return this.getAveragePercentRate(item.data.hh_Data);
	},
	getHandHygieneDetail: function(item) {
		// var numerator = item.data.hh_N;
		// var denominator = item.data.hh_D;



		var info = this.getDisplayArray(item.data.hh_Data);

  	// calculate percentage rates]
  	// for (i = 0; i < info.length; i++) {
      //
  	// 	info[i].rate = info[i].value;
  	// }
  	return info;
	},

	getSABInfectionRate: function(item) {
		var numerator = item.data.si_N;
		var denominator = item.data.si_D;
		return this.get10KRate(numerator, denominator);
	},
	getSABInfectionRangeRate: function(item, start, end) {
		var numerator = item.data.si_N;
		var denominator = item.data.si_D;
		return this.get10KRangeRate(numerator, denominator, start, end);
	},
	getSABInfectionDetail: function(item) {
		var numerator = item.data.si_N;
		var denominator = item.data.si_D;
		var info = this.getQuarterMergedArray(numerator, denominator);

  	// calculate rates
  	for (i = 0; i < info.length; i++) {
  		var rate = 10000 * info[i].numerator / info[i].denominator ;
  		info[i].rate = rate.toFixed(_config.fixedNumber10K);
  	}
  	return info;
	},

	getPatientSurveyCareRate: function(item) {
		var numerator = item.data.psc_N;
		var denominator = item.data.ps_D;
		return this.getPercentRate(numerator, denominator);
	},
	getPatientSurveyCareRangeRate: function(item, start, end) {
		var numerator = item.data.psc_N;
		var denominator = item.data.ps_D;
		return this.getPercentRangeRate(numerator, denominator, start, end);
	},
	getPatientSurveyCareDetail: function(item) {
		var numerator = item.data.psc_N;
		var denominator = item.data.ps_D;
		var info = this.getQuarterMergedArray(numerator, denominator);

  	// calculate rates
  	for (i = 0; i < info.length; i++) {
  		var rate = 100 * info[i].numerator / info[i].denominator;
  		info[i].rate = rate.toFixed(_config.fixedNumber);
  	}
  	return info;
	},

	getPatientWillingnessRecommendRate: function(item) {
		var numerator = item.data.psr_N;
		var denominator = item.data.ps_D;
		return this.getPercentRate(numerator, denominator);
	},
	getPatientWillingnessRecommendRangeRate: function(item, start, end) {
	    var numerator = item.data.psr_N;
		var denominator = item.data.ps_D;
		return this.getPercentRangeRate(numerator, denominator, start, end);
	},
	getPatientWillingnessRecommendDetail: function(item) {
	    var numerator = item.data.psr_N;
		var denominator = item.data.ps_D;
		var info = this.getQuarterMergedArray(numerator, denominator);

  	// calculate rates
  	for (i = 0; i < info.length; i++) {
  		var rate = 100 * info[i].numerator / info[i].denominator;
  		info[i].rate = rate.toFixed(_config.fixedNumber);
  	}
  	return info;
	},

	getStage4PressureInjuryRate: function(item) {
		var numerator = item.data.s4_N;
		var denominator = item.data.tb_D;
		return this.get10KRate(numerator, denominator);
	},
	getStage4PressureInjuryRangeRate: function(item, start, end) {
	    var numerator = item.data.s4_N;
		var denominator = item.data.tb_D;
		return this.get10KRangeRate(numerator, denominator, start, end);
	},
	getStage4PressureInjuryDetail: function(item) {
	    var numerator = item.data.s4_N;
		var denominator = item.data.tb_D;
		var info = this.getQuarterMergedArray(numerator, denominator);

		// calculate rates
		for (i = 0; i < info.length; i++) {
			var rate = 10000 * info[i].numerator / info[i].denominator;
			info[i].rate = rate.toFixed(_config.fixedNumber10K);
		}
		return info;
	},

	getFallsFractureClosedHeadInjuryRate: function(item) {
		var numerator = item.data.fi_N;
		var denominator = item.data.tb_D;
		return this.get10KRate(numerator, denominator);
	},
	getFallsFractureClosedHeadInjuryRangeRate: function(item, start, end) {
	    var numerator = item.data.fi_N;
		var denominator = item.data.tb_D;
		return this.get10KRangeRate(numerator, denominator, start, end);
	},
	getFallsFractureClosedHeadInjuryDetail: function(item) {
	    var numerator = item.data.fi_N;
		var denominator = item.data.tb_D;
		var info = this.getQuarterMergedArray(numerator, denominator);

		// calculate rates
		for (i = 0; i < info.length; i++) {
			var rate = 10000 * info[i].numerator / info[i].denominator;
			info[i].rate = rate.toFixed(_config.fixedNumber10K);
		}
		return info;
	},

	getFallsResultingDeathRate: function(item) {
		var numerator = item.data.fd_N;
		var denominator = item.data.tb_D;
		return this.get10KRate(numerator, denominator);
	},
	getFallsResultingDeathRangeRate: function(item, start, end) {
		var numerator = item.data.fd_N;
		var denominator = item.data.tb_D;
		return this.get10KRangeRate(numerator, denominator, start, end);
	},
	getFallsResultingDeathDetail: function(item) {
		var numerator = item.data.fd_N;
		var denominator = item.data.tb_D;
		var info = this.getQuarterMergedArray(numerator, denominator);

		// calculate rates
		for (i = 0; i < info.length; i++) {
			var rate = 10000 * info[i].numerator / info[i].denominator;
			info[i].rate = rate.toFixed(_config.fixedNumber10K);
		}
		return info;
	},

	getMedicationErrorResultingDeathRate: function(item) {
		var numerator = item.data.md_N;
		var denominator = item.data.tb_D;
		return this.get10KRate(numerator, denominator);
	},
	getMedicationErrorResultingDeathRangeRate: function(item, start, end) {
		var numerator = item.data.md_N;
		var denominator = item.data.tb_D;
		return this.get10KRangeRate(numerator, denominator, start, end);
	},
	getMedicationErrorResultingDeathDetail: function(item) {
		var numerator = item.data.md_N;
		var denominator = item.data.tb_D;
		var info = this.getQuarterMergedArray(numerator, denominator);

		// calculate rates
		for (i = 0; i < info.length; i++) {
			var rate = 10000 * info[i].numerator / info[i].denominator;
			info[i].rate = rate.toFixed(_config.fixedNumber10K);
		}
		return info;
	},

	getQuarterYear: function(str) {
		var array = str.split('/');
		var year = Number(array[2])
		// short year
		if (array[2].length != 4) {
			year += 2000;
		}
		var month = Number(array[0]) - 1;
		var day = Number(array[1]);
		var date = new Date(year, month, day);
		// console.log(date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear());
		if (date == null) {
			return null;
		} else {
			var year = date.getFullYear().toString();
			var month = date.getMonth() + 1;
			var quarter = (Math.ceil(month / 3));
			return year + '-Q' + quarter;
		}
	},
	isRange: function(term, start, end) {
		var termDate = this.termToDate(term);
		var startDate = this.termToDate(start);
		var endDate = this.termToDate(end);
		return (termDate.valueOf() >= startDate.valueOf() &&
			termDate.valueOf() <= endDate.valueOf());
	},
	termToDate: function(term) {
		var array = term.split('/');
		var year = Number(array[2])
		// short year
		if (array[2].length != 4) {
			year += 2000;
		}
		var month = Number(array[0]) - 1;
		var day = Number(array[1]);
		var date = new Date(year, month, day);
		return date;
	},

    getAveragePercentRate: function(rates) {
        if (!this.isAvailable(rates)) {
            return null;
        }
        var rateSum = 0;
        for (var i = 0; i < rates.length; i++) {
            if (rates[i].value != null) {
                var numeratorValue = parseFloat(rates[i].value);
                rateSum += numeratorValue;
            }
        }
        var rate = 0;
        if (rateSum != 0) {
            rate = rateSum / rates.length + 1;
        }
        return rate.toFixed(_config.fixedNumber);
    },

	getPercentRate: function(numerator, denominator) {
		if (!this.isAvailable(numerator) || !this.isAvailable(denominator)) {
			return null;
		}
		var numeratorSum = 0;
		var denominatorSum = 0;
		for (var i = 0; i < numerator.length; i++) {
			if (numerator[i].value != null) {
				var numeratorValue = parseFloat(numerator[i].value);
				numeratorSum += numeratorValue;
			}
			if (denominator[i].value != null) {
				var denominatorValue = parseFloat(denominator[i].value);
				denominatorSum += denominatorValue;
			}
		}
		var rate = 0;
		if (denominatorSum != 0) {
			rate = numeratorSum / denominatorSum * 100;
		}
		return rate.toFixed(_config.fixedNumber);
	},

    getDataInRange: function(rates, start, end) {
        if (!this.isAvailable(rates)) {
            return null;
        }

        var loopCounter = 0;
        var rate = 0;

        for (var i = 0; i < rates.length; i++) {
            if (rates[i].value != null) {
                if (rates[i].term == end) {
                    if (rates[i].value == "")
                    {
                        return "N/A";
                    }

                    rate = parseFloat(rates[i].value);
                    loopCounter++;
                }
            }
        }

        if (loopCounter == 0) {
            return "N/A";
        }

        return rate.toFixed(_config.fixedNumber);
    },

	getPercentRangeRate: function(numerator, denominator, start, end) {
		if (!this.isAvailable(numerator) || !this.isAvailable(denominator)) {
			return null;
		}

		var loopCounter = 0;

		var numeratorSum = 0;
		var denominatorSum = 0;
		for (var i = 0; i < numerator.length; i++) {
			if (numerator[i].value != null) {
				if (this.isRange(numerator[i].term, start, end)) {
					var numeratorValue = parseFloat(numerator[i].value);
					numeratorSum += numeratorValue;
                    loopCounter++;
				}
			}
			if (denominator[i].value != null) {
				if (this.isRange(numerator[i].term, start, end)) {
					var denominatorValue = parseFloat(denominator[i].value);
					denominatorSum += denominatorValue;
				}
			}
		}

		if (loopCounter == 0) {
		    return "N/A";
        }

		var rate = 0;
		if (denominatorSum != 0) {
			rate = numeratorSum / denominatorSum * 100;
		}

		return rate.toFixed(_config.fixedNumber);
	},

	get10KRate: function(numerator, denominator) {
		if (numerator == null || denominator == null || numerator.length != denominator.length) {
			return null;
		}
		var numeratorSum = 0;
		var denominatorSum = 0;
		for (var i = 0; i < numerator.length; i++) {
			if (numerator[i].value != null) {
				var numeratorValue = parseFloat(numerator[i].value);
				numeratorSum += numeratorValue;
			}
			if (denominator[i].value != null) {
				var denominatorValue = parseFloat(denominator[i].value);
				denominatorSum += denominatorValue;
			}
		}
		var rate = 0;
		if (denominatorSum != 0) {
			rate = 10000 * numeratorSum / denominatorSum;
		}
		return rate.toFixed(_config.fixedNumber10K);
	},
	get10KRangeRate: function(numerator, denominator, start, end) {

		if (numerator == null || denominator == null) {
			return "N/A";
		}

        var nCounter = 0;
        var dCounter = 0;

		var numeratorSum = 0;
		var denominatorSum = 0;


		for (var i = 0; i < numerator.length; i++) {
			if (numerator[i].value != null) {
				if (this.isRange(numerator[i].term, start, end)) {
					var numeratorValue = parseFloat(numerator[i].value);
					numeratorSum += numeratorValue;
                    nCounter++;
				}
			}
		}

        for (var i = 0; i < denominator.length; i++) {
            if (denominator[i].value != null) {
                if (this.isRange(denominator[i].term, start, end)) {
                    var denominatorValue = parseFloat(denominator[i].value);
                    denominatorSum += denominatorValue;
                    dCounter++
                }
            }
        }

        if (dCounter == 0 || nCounter == 0 || nCounter != dCounter) {
            return "N/A";
        }

		var rate = 0;
		if (denominatorSum != 0) {
			rate = 10000 * numeratorSum / denominatorSum;
		}


		//console.log('numSum: ' + numeratorSum + ', denoSum: ' + denominatorSum + ', rate: ' + rate);
		return rate.toFixed(_config.fixedNumber10K);
	},

	getQuarterMergedArray: function(numerator, denominator) {
		var tempArray = [];
		if (!this.isAvailable(numerator) || !this.isAvailable(denominator)) {
			return info;
		}

		for (var i = 0; i < numerator.length; i++) {
			var cell = numerator[i];
			var cellQName = this.getQuarterYear(cell.term);
			var existing = false;
			for (var j = 0; j < tempArray.length; j++) {
				if (cellQName == tempArray[j].term) {
					existing = true;
					break;
				}
			}
			if (existing == true) {
				tempArray[j].numerator += parseFloat(cell.value);
				tempArray[j].denominator += parseFloat(denominator[i].value);
			} else {
				tempArray.push({
					term: cellQName,
					numerator: parseFloat(cell.value),
					denominator: parseFloat(denominator[i].value),
					rate: null
				});
			}
		}

		return tempArray;
	},

    getDisplayArray: function(numerator) {
        var tempArray = [];
        if (!this.isAvailable(numerator)) {
            return tempArray;
        }

        for (var i = 0; i < numerator.length; i++) {
            var cell = numerator[i];
            var cellQName = cell.term;
            var existing = false;
            for (var j = 0; j < tempArray.length; j++) {
                if (cellQName == tempArray[j].term) {
                    existing = true;
                    break;
                }
            }

            tempArray.push({
                term: cellQName,
                rate: parseFloat(cell.value)
            });

        }

        return tempArray;
    },


	isAvailable: function(object) {
		if (object != null && object != undefined && object.length > 0) {
			return true;
		} else {
			return false;
		}
	}
}

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

function moveDetail(h, e){
	e.preventDefault();
	location.href = h+"hospitalName="+hospitalName;
}