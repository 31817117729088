
var api_base = "/api/v1/";


$(document).ready(function () {

    $(".formwidget").each(function () {

        //var id = $(this).attr('id');
        //$('#' + id).prependTo('#bizform-form-section');     

        $("#bizform-form-section div").removeClass("offset-lg-2");
        $("#bizform-form-section .col-lg-4").addClass("col-lg-6");
        $("#bizform-form-section div").removeClass("col-lg-4");

        $("#bizform-form-section .col-lg-8").addClass("col-lg-12");
        $("#bizform-form-section div").removeClass("col-lg-8");

        $(".inforline-form div").removeClass("offset-lg-2");
        $(".inforline-form .col-lg-4").addClass("col-lg-6");
        $(".inforline-form div").removeClass("col-lg-4");

        $(".inforline-form .col-lg-8").addClass("col-lg-12");
        $(".inforline-form div").removeClass("col-lg-8");

    });

    $(".main-nav.main-nav-mater li a").each(function () {

        if (window.location.href.indexOf($(this).prop("href")) > -1) {
            $(this).find("span").addClass("menu-overlay-d");
        }
        else {
            $(this).find("span").removeClass("menu-overlay-d");
        }

    });

    $('#icon-mail').on('click', function () {
        $('.inforline-form').stop(true, true).slideDown();
        $(this).hide();
        $('#icon-form-close').show();
    });

    $('#icon-form-close').on('click', function () {
        $('.inforline-form').stop(true, true).slideUp();
        $(this).hide();
        $('#icon-mail').show();
    });

    $('.orthopaedics-nav').find('#primary-nav').clone(true, true).insertAfter(".nav-wrapper #primary-nav");
    $('.nav-wrapper #primary-nav.ortho-nav .main-nav.main-nav-ortho').prepend('<li class="orthopaedics-home"> <a href="/services/orthopaedics" class="color-white"> <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23"><defs><style>.a{fill:none;stroke:#fff;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-3.5 -2)"><path class="a" d="M4.5,10.35,14,3l9.5,7.35V21.9A2.106,2.106,0,0,1,21.389,24H6.611A2.106,2.106,0,0,1,4.5,21.9Z" transform="translate(0 0)"/><path class="a" d="M13.5,28.5V18h6.333V28.5" transform="translate(-2.667 -4.5)"/></g></svg>Orthopaedics </a></li>');
    $('.nav-wrapper #primary-nav.ortho-nav .main-nav.main-nav-mater').prepend('<li class="orthopaedics-home"> <a href="/services/maternity" class="color-white"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23"><defs><style>.a{fill:none;stroke:#fff;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-3.5 -2)"><path class="a" d="M4.5,10.35,14,3l9.5,7.35V21.9A2.106,2.106,0,0,1,21.389,24H6.611A2.106,2.106,0,0,1,4.5,21.9Z" transform="translate(0 0)"></path><path class="a" d="M13.5,28.5V18h6.333V28.5" transform="translate(-2.667 -4.5)"></path></g></svg>Maternity Home</a></li>');
    $('.nav-wrapper #primary-nav.ortho-nav .main-nav').prepend('<li class="mega-parent"> <a href="/"> SVPH Home </a></li>');

});
if (document.getElementsByClassName("current")[0] != undefined) {
    if (document.getElementsByClassName('current')[0].innerHTML.includes(', VIC') || document.getElementsByClassName('current')[0].innerHTML.includes(', NSW') || document.getElementsByClassName('current')[0].innerHTML.includes(', QLD')) {
        window.onload = localStorage.setItem("Hospital", document.getElementsByClassName('current')[0].innerText);
    }
	if ($("a[href^='/services?']")[0] !== undefined) {
            window.onload = localStorage.setItem("SeviceURl", $("a[href^='/services?']")[0].href);
    }
    if ($("a[href^='/services?']")[0] !== undefined) {
            window.onload = localStorage.setItem("SpecialistURl", $("a[href^='/specialists?']")[0].href);
    }
}
window.onload = setHospitalValue();

function OrthoContact(form) {

    if (form === 'ortho-form') {

        $('.inforline-form').stop(true, true).slideDown();
        $('#icon-mail').hide();
        $('#icon-form-close').show();
    }
}

function onTreatmentChange(id) {

    $.ajax({
        type: "GET",
        url: api_base + "Service/GetProcedureFilterData",
        data: {
            treatmentId: id
        },
        contentType: "application/json",
        success: function (data) {

            $("#drProcedure").empty();

            for (var i = 0; i < data.length; i++) {

                var o = new Option(data[i].name, data[i].value);
                $("#drProcedure").append(o);

            }

            if (data.length > 0) {

                $("#p-title").html(data[0].title);
                $("#p-body").html(data[0].body);
            }

        }
    });

}


function onProcedureChange(id) {

    $.ajax({
        type: "GET",
        url: api_base + "Service/GetProcedureData",
        data: {
            procedureId: id
        },
        contentType: "application/json",
        success: function (data) {

            $("#p-title").html(data.title);
            $("#p-body").html(data.body);


        }
    });

}


function filterSelection(c) {

    var x, i;
    x = document.getElementsByClassName("filter-div");

    if (c == "All") {

        $(".link-filter").each(function () {
            $(this).removeClass("link-filter-selected");
        });

        $(".link-filter." + c).addClass("link-filter-selected");

        c = "";

        for (i = 0; i < x.length; i++) {
            svRemoveClass(x[i], "show");
            if (x[i].className.indexOf(c) > -1) svAddClass(x[i], "show");
        }
    }
    else {

        for (i = 0; i < x.length; i++) {
            svRemoveClass(x[i], "show");
            if (x[i].className.indexOf(c) > -1) svAddClass(x[i], "show");
        }

        $(".link-filter").each(function () {
            $(this).removeClass("link-filter-selected");
        });

        $(".link-filter." + c).addClass("link-filter-selected");
    }
}

function svAddClass(element, name) {

    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");

    for (i = 0; i < arr2.length; i++) {
        if (arr1.indexOf(arr2[i]) == -1) { element.className += " " + arr2[i]; }
    }

}

function svRemoveClass(element, name) {

    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
        while (arr1.indexOf(arr2[i]) > -1) {
            arr1.splice(arr1.indexOf(arr2[i]), 1);
        }
    }
    element.className = arr1.join(" ");
}
