(function ($, window, undefined) {

    function MainHeader(args) {

        this.defaults = {
            selectors: {
                mainHeader: '#main-header',
                navWrapper: '.nav-wrapper',
                searchWrapper: '.site-search',
                btnNavTrigger: '.btn-nav-trigger',
                btnOpenSearch: '.btn-search-trigger',
                btnOpenSearch_desktop: '.btn-search',
                btnCloseSearch: '.btn-close-search',
                searchInput: '#site-search',
                skipToLinks: '[data-skip-to-button]',
            },

            state : {
                navOpen: false,
            }
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$mainHeader = $(this.options.selectors.mainHeader);
        this.$btnNavTrigger = this.parent(this.options.selectors.btnNavTrigger);
        this.$btnOpenSearch = this.parent(this.options.selectors.btnOpenSearch);
        this.$btnOpenSearch_desktop = this.parent(this.options.selectors.btnOpenSearch_desktop);
        this.$btnCloseSearch = this.parent(this.options.selectors.btnCloseSearch);
        this.$searchInput = this.parent(this.options.selectors.searchInput);
        this.$skipToLinks = this.parent(this.options.selectors.skipToLinks);

        this.bindEvents();
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    MainHeader.prototype.parent = function (selector) {
        return $(selector, this.$mainHeader);
    };

    /*
    Interface events -- mobile specific
     */
    MainHeader.prototype.openOffCanvasNav = function () {
        this.lockViewport();
        this.$btnNavTrigger.addClass('is-active');
        $('body').addClass('nav-open');
        this.options.state.navOpen = true;
    };

    MainHeader.prototype.closeOffCanvasNav = function () {
        this.unlockViewport();
        this.$btnNavTrigger.removeClass('is-active');
        $('body').removeClass('nav-open');
        this.options.state.navOpen = false;
    };

    /**
     * Shared interface events
     */
    MainHeader.prototype.lockViewport = function () {
        $('html').addClass('viewport-locked');
    };

    MainHeader.prototype.unlockViewport = function () {
        $('html').removeClass('viewport-locked');
    };


    MainHeader.prototype.openSearch = function () {
        $('body').addClass('search-open');
        this.$searchInput.focus();

    };

    MainHeader.prototype.closeSearch = function () {
        $('body').removeClass('search-open');
        this.$searchInput.blur();
    };


    MainHeader.prototype.bindEvents = function () {
        var self = this;

        this.$skipToLinks.click(function(e){
            var panel = $(e.target.getAttribute("href"));
            console.log(panel)
				panel.focus();
        });

        this.$btnNavTrigger.click(function(){
            if (self.options.state.navOpen === false){
                self.openOffCanvasNav();
            } else {
                self.closeOffCanvasNav();
            }
        });

        this.$btnOpenSearch_desktop.click(function(){
            self.openSearch();
        });

        this.$btnOpenSearch.click(function(){
            self.openSearch();
        });

        this.$btnCloseSearch.click(function(){
            self.closeSearch();
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.MainHeader = MainHeader;

})(jQuery, window);


