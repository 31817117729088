(function ($, window, undefined) {

    function GlobalWidgets(args) {};

    GlobalWidgets.prototype.init = function () {
        this.AccordionContent = new SVHA.Widgets.AccordionContent({
            firstOpen: false
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.GlobalWidgets = new GlobalWidgets;

})(jQuery, window);