(function ($, window, undefined) {

    function Autocomplete(args) {
        this.defaults = {
            selectors: {
                widgetContainer: "[data-autocomplete]",
            }
        };

        this.options = $.extend(true, {}, this.defaults, args);
        this.$widgetContainer = $(this.options.selectors.widgetContainer);

        this.init();
    }

    Autocomplete.prototype.init = function () {
        var self = this;

        var el = this.$widgetContainer[0];

        var fetchFunction = function (url, text, update) {
            fetch(url + '?q=' + encodeURIComponent(text))
            .then(function (response) {
                if (response.status == 200) {
                    return response.json();
                }
                return [];
            })
            .then(function (myJson) {
                el.classList.remove('c-text-box__field--loading');
                const suggestions = myJson;
                if (suggestions.length > 0) {
                    el.setAttribute("data-result", JSON.stringify(suggestions[0]));
                } else {
                    el.removeAttribute("data-result");
                }

                if (suggestions.IsExtra === undefined) {
                    update(suggestions);
                } else {
                    if (!!suggestions.IsExtra) {
                        setMessage(el, '<strong>Having trouble?</strong> Try entering your suburb followed by your postcode');
                    }

                    update(suggestions.Postcodes);
                }
            });
        };

        new autocomplete({
            input: el,
            debounceWaitMs: 250,
            fetch: function(text, update) {
                executeOnBlur = true;
                text = text.toLowerCase();
                var url = el.dataset.autocomplete;
                el.classList.add('c-text-box__field--loading');
                fetchFunction(url, text, update);
            },
            onSelect: function (item, e) {
                el.setAttribute("data-result", JSON.stringify(item));
                el.value = item.label;
                executeOnBlur = false;
                el.blur();
            }
        });

    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.Autocomplete = Autocomplete;

})(jQuery, window);



