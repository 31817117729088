(function ($, window, undefined) {

    function CareTeamSlider(args) {
        this.swiper;
        this.defaults = {
            selectors: {
                widgetContainer: '[data-care-team]',
                swiperContainer: '[data-care-team-swiper-container]',
                btnNext: '[data-care-team-swiper-next]',
                btnPrev: '[data-care-team-swiper-prev]'
            }
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$swiperContainer = this.parent(this.options.selectors.swiperContainer);
        this.$btnNext = this.parent(this.options.selectors.btnNext);
        this.$btnPrev = this.parent(this.options.selectors.btnPrev);

        this.init()
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    CareTeamSlider.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };

    CareTeamSlider.prototype.init = function () {
        var self = this;

        this.swiper = new Swiper(this.$swiperContainer, {
            spaceBetween: -100,
            centeredSlides: true,
            centeredSlidesBounds: true,
            loop: false,

            breakpoints: {
                768: {
                    grabCursor: true,
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                992: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                }
            },

            // Navigation arrows
            navigation: {
                nextEl: this.$btnNext,
                prevEl: this.$btnPrev,
            },
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.CareTeamSlider = CareTeamSlider;

})(jQuery, window);



