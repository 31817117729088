(function ($, window, undefined) {

    function PrimaryNav(args) {
        this.activeNavParent = null;

        this.defaults = {
            scrollOnOpen: true,
            scrollDelay: 250,
            scrollSpeed: 500,
            multipleOpen: false,
            selectors: {
                mainNav: '.main-nav',
                mainNavTrigger: '.main-trigger',
                mainNavSubNav: '.subnav',
                navWrapper: '.nav-wrapper'
            },
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$mainNav = $(this.options.selectors.mainNav);
        this.$mainNavTrigger = this.$mainNav.find(this.options.selectors.mainNavTrigger);
        this.$mainNavSubNav = this.$mainNav.find(this.options.selectors.mainNavSubNav);
        this.$navWrapper = this.$mainNav.parents(this.options.selectors.navWrapper);

        var self = this;

        if(SVHA.Utility.StateManager.ssm.isActive('mobile')) {
            this.initMobileAccordion();
        }

        SVHA.Utility.StateManager.addOnEnter('desktop', function() {
            self.resetMobileAccordion();
        });

        SVHA.Utility.StateManager.addOnEnter('mobile', function() {
            self.initMobileAccordion();
        });

        this.bindEvents();
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    PrimaryNav.prototype.parent = function (selector) {
        return $(selector, this.$container);
    };

    /*
    Interface events -- mobile specific
     */
    PrimaryNav.prototype.scrollToBtn = function (targetBtn){

        var btnPos = targetBtn.offset(); //Relative to document
        var offSet = 87;  //Height of nav bar

        var scrollAmount = btnPos.top - offSet;

        if (scrollAmount != 0){
            this.$navWrapper.animate({
                scrollTop: '+='+scrollAmount+'px'
            }, this.options.scrollSpeed);
        }
    };


    PrimaryNav.prototype.openSubNavAccordion = function (parent, targetSubNav){
        parent.addClass('active');
        var openHeight = targetSubNav.attr('data-open-height');

        targetSubNav.css({
            maxHeight: openHeight+'px',
            display: 'block'
        });

        if (this.options.scrollOnOpen === true){
            var self = this;
            setTimeout(function(){
                self.scrollToBtn(parent);
            }, self.options.scrollDelay);
        }
    };

    PrimaryNav.prototype.closeSubNavAccordion = function (parent, targetSubNav){
        if (!targetSubNav){
            targetSubNav = parent.find(this.options.selectors.mainNavSubNav);
        }

        parent.removeClass('active');
        targetSubNav.css({
            maxHeight: 0
        });
    };

    /*
    Interface events -- desktop + large screens
     */
    PrimaryNav.prototype.showSubNav = function (parent){
        if(this.activeNavItem != null || undefined){
            this.hideSubNav(this.activeNavItem);
        }

        parent.addClass('active');
        this.activeNavItem = parent;

    };

    PrimaryNav.prototype.hideSubNav = function (parent){
        parent.removeClass('active');
    };


    /*
    Event binding and initialising methods
     */
    PrimaryNav.prototype.initMobileAccordion = function(){
        this.$mainNavSubNav.each(function(){
            var defaultHeight = $(this).height();

            $(this).attr('data-open-height', defaultHeight);
            $(this).css({
                display: 'block',
                maxHeight: '0px'
            });
        })
    };

    PrimaryNav.prototype.resetMobileAccordion = function(){
        this.$mainNavSubNav.each(function(){
            $(this).removeAttr("style");
        })
    };

    /*
    Mobile event binding
    */
    PrimaryNav.prototype.bindEvents = function () {
        var self = this;
        this.$mainNavTrigger.click(function(){

            var $parentListItem = $(this).parent();
            var $targetSubNav = $(this).next(self.options.selectors.mainNavSubNav);

            if ($parentListItem.hasClass('active')) {

                //Target is active, lets close
                if(SVHA.Utility.StateManager.ssm.isActive('mobile')) {

                    self.closeSubNavAccordion($parentListItem, $targetSubNav);
                    self.activeNavParent = null;

                } else {
                    self.hideSubNav($parentListItem);
                }

            } else {

                //Target is NOT active, lets open
                if(SVHA.Utility.StateManager.ssm.isActive('mobile')) {

                    if (self.activeNavParent && self.options.multipleOpen === false){
                        self.closeSubNavAccordion(self.activeNavParent);
                    }


                    self.openSubNavAccordion($parentListItem, $targetSubNav);
                    self.activeNavParent = $parentListItem;

                } else {
                    self.showSubNav($parentListItem);
                }
            }
        });


        this.$mainNavTrigger.parents('li').hoverIntent({
            over: function(){},
            out: function(){
                var $elem = $(this);
                self.hideSubNav($elem);
            },
            timeout: 500
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.PrimaryNav = PrimaryNav;

})(jQuery, window);



