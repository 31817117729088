    
    function setHospitalValue() {  
        if (localStorage.getItem("Hospital") !== "" && $("[id$='Hospital_SelectedValue']").length > 0 && document.referrer.includes('/hospitals/')) {
            $("[id$='Hospital_SelectedValue'] option").map(function () {
		if ($(this).text() == localStorage.getItem("Hospital")) return this;
			}).attr('selected', 'selected');            
        }
		var url = new URL(document.URL);
		var hospital = url.searchParams.get("hospital");
		if(hospital != null){
			hospital = hospital.charAt(0).toUpperCase() + hospital.slice(1)
			$("[id$='Hospital_SelectedValue'] option:contains("+ hospital +")").attr('selected', 'selected');
		}
	    if (localStorage.getItem("Hospital") !== "" && document.URL.includes('/hospitals/')){
                if($("[class$='main-nav']")[0] !== undefined && localStorage.getItem("SeviceURl") !== null)			
				$($("[class$='main-nav']")[0].children[1].firstElementChild).attr("href", localStorage.getItem("SeviceURl"));			    
				localStorage.removeItem("SeviceURl");
		}
		if (localStorage.getItem("Hospital") !== "" && document.URL.includes('/hospitals/')) {
		       if($("[class$='main-nav']")[0] !== undefined && localStorage.getItem("SpecialistURl") !== null)	
			   $($($($("[class$='main-nav']")[0].children[2].firstElementChild).next())[0].children[0].children[0].firstElementChild).attr("href", localStorage.getItem("SpecialistURl"));
		       localStorage.removeItem("SpecialistURl");
		}
		
    }
	
	