(function ($, window, undefined) {

    function HighlightStrap(args) {
        this.swiper;
        this.defaults = {
            selectors: {
                widgetContainer: '[data-highlight-strap]',
                swiperContainer: '[data-highlight-strap-swiper-container]',
            }
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$swiperContainer = this.parent(this.options.selectors.swiperContainer);

        this.init()
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    HighlightStrap.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };


    HighlightStrap.prototype.initSwiper = function(){
        this.swiper = new Swiper(this.$swiperContainer, {
            slidesPerView: 1,
            spaceBetween: 0,
            grabCursor: true,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
            },
        });
    };

    HighlightStrap.prototype.destroySwiper = function () {
        this.swiper.destroy();
    };

    HighlightStrap.prototype.init = function () {
        var self = this;

        if(SVHA.Utility.StateManager.ssm.isActive('mobile')){
            this.initSwiper();
        }

        SVHA.Utility.StateManager.addOnEnter('desktop', function() {
            if(self.swiper){
                self.destroySwiper();
            }
        });

        SVHA.Utility.StateManager.addOnEnter('mobile', function() {
            if(self.swiper) {
                self.initSwiper();
            }
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.HighlightStrap = HighlightStrap;

})(jQuery, window);



