


(function ($, window, undefined) {

    function TestimonialSlider(args) {
        this.swiper;
        this.defaults = {
            selectors: {
                widgetContainer: '[data-testimonial]',
                swiperContainer: '[data-testimonial-swiper-container]',
                btnNext: '[data-testimonial-swiper-next]',
                btnPrev: '[data-testimonial-swiper-prev]',
                pagination: '[data-testimonial-swiper-pagination]',
            }
        };

        this.options = $.extend(true, {}, this.defaults, args);
        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$swiperContainer = this.parent(this.options.selectors.swiperContainer);
        this.$btnNext = this.parent(this.options.selectors.btnNext);
        this.$btnPrev = this.parent(this.options.selectors.btnPrev);
        this.$pagination = this.parent(this.options.selectors.pagination);

        this.init()
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    TestimonialSlider.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };

    TestimonialSlider.prototype.init = function () {
        var self = this;

        this.swiper = new Swiper(this.$swiperContainer, {
            spaceBetween: 30,
            centeredSlides: true,
            centeredSlidesBounds: true,
            loop: false,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            pagination: {
                el: this.$pagination,
                type: 'bullets',
                clickable: 'true'
            },

            breakpoints: {
                992: {
                    // freeMode: true,
                    grabCursor: true,
                    slidesPerView: 1,
                    slidesOffsetBefore: 0,
                }
            },

            // Navigation arrows
            navigation: {
                nextEl: this.$btnNext,
                prevEl: this.$btnPrev,
            },
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.TestimonialSlider = TestimonialSlider;

})(jQuery, window);



