(function ($, window, undefined) {

    function ImageSlider(args) {
        this.swiper;
        this.defaults = {
            selectors: {
                widgetContainer: '[data-image-slider]',
                swiperContainer: '[data-image-swiper-container]',
                swiperSlide: '[data-image-swiper-slide]',
                imgDescTxt: '[data-image-desc]',
                nextBtn: '[data-image-swiper-next]',
                prevBtn: '[data-image-swiper-prev]',
                pagination: '[data-image-pagination-block]'
            }
        };
        
        this.options = $.extend(true, {}, this.defaults, args);
        
        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$swiperContainer = this.parent(this.options.selectors.swiperContainer);
        this.$imgDescTxt = this.parent(this.options.selectors.imgDescTxt);
        this.$nextBtn = this.parent(this.options.selectors.nextBtn);
        this.$prevBtn = this.parent(this.options.selectors.prevBtn);
        this.$pagination = this.parent(this.options.selectors.pagination);

        this.init()
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    ImageSlider.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };

    ImageSlider.prototype.getSlide = function(targetIndex){
        return this.$swiperContainer.find(this.options.selectors.swiperSlide).eq(targetIndex);
    };

    ImageSlider.prototype.setDescTxt = function (txtString) {
        this.$imgDescTxt.append('<p class="text">'+txtString+'</p>');
    };

    ImageSlider.prototype.appendLink = function (txtString, url, isExternal) {
        if (isExternal){
            this.$imgDescTxt.append('<a class="link" href="'+url+'" target="_blank" rel="noreferrer noopener">'+txtString+'</a>');
        } else {
            this.$imgDescTxt.append('<a class="link" href="'+url+'">'+txtString+'</a>');
        }
    };

    ImageSlider.prototype.updateDesc = function(slideIndex){
        var $targetSlide = this.getSlide(slideIndex);
        var imgTxt = $targetSlide.find('.attr-holder').attr('data-caption');
        var linkUrl = $targetSlide.find('.attr-holder').attr('data-caption-link');

        // checks if the link is external or not
        var isExternal = linkUrl.indexOf("https://") > -1 || linkUrl.indexOf("http://") > -1 ? true : false ;
        //Clear it first
        this.$imgDescTxt.html('');

        if(linkUrl){
            this.appendLink(imgTxt, linkUrl, isExternal)
        } else {
            this.setDescTxt(imgTxt);
        }
    };

    ImageSlider.prototype.slideStart = function(){
        var activeSlide = this.swiper.activeIndex;
        this.updateDesc(activeSlide)
    };

    ImageSlider.prototype.init = function () {
        var self = this;

        this.updateDesc(0);

        this.swiper = new Swiper(this.$swiperContainer, {
            pagination: {
                el: this.$pagination,
                type: 'fraction',
            },
            navigation: {
                nextEl: this.$nextBtn,
                prevEl: this.$prevBtn,
            },

            on: {
                slideChangeTransitionStart: function(){
                    self.slideStart();
                }
            }
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.ImageSlider = ImageSlider;

})(jQuery, window);



