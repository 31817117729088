(function($){

    $(function () {
        /*
        Vendor inits
         */
        picturefill();
        svg4everybody();

        /*
        Utilities
         */
        SVHA.Utility.StateManager.run();

        /*
        Site specific widget intits
         */ 
        SVHA.Widgets.GlobalWidgets.init();

        this.MainHeader = new SVHA.Widgets.MainHeader();
        this.PrimaryNav = new SVHA.Widgets.PrimaryNav();

        this.FooterAccordion = new SVHA.Widgets.AccordionContent({
            mobileOnly: true,
            selectors: {
                widgetContainer: '#main-footer',
                accordionItem: '.accordion-item',
                btnToggle: '.btn-accordion-trigger',
                contentBlock: '.accordion-content'
            }
        });

        // CARE TEAM SLIDER
        var careTeamSliderElements = document.querySelectorAll('[data-care-team]');
        for (var i = 0; i < careTeamSliderElements.length; ++i) {
            new SVHA.Widgets.CareTeamSlider({
                selectors: {
                    widgetContainer: careTeamSliderElements[i],
                }
            })
        }

        // TESTMONIAL SLIDER
        var testimonialSliderElements = document.querySelectorAll('[data-testimonial]');
        for (var i = 0; i < testimonialSliderElements.length; ++i) {
            new SVHA.Widgets.TestimonialSlider({
                selectors: {
                    widgetContainer: testimonialSliderElements[i],
                }
            })
        }
        // Image slider
        var imageSliderElements = document.querySelectorAll('[data-image-slider]');
        for (var i = 0; i < imageSliderElements.length; ++i) {
            new SVHA.Widgets.ImageSlider({
                selectors: {
                    widgetContainer: imageSliderElements[i],
                }
            })
        }

        //Highlight straps
        var highlightElements = document.querySelectorAll('[data-highlight-strap]');
        for (var i = 0; i < highlightElements.length; ++i) {
            new SVHA.Widgets.HighlightStrap({
                selectors: {
                    widgetContainer: highlightElements[i],
                }
            })
        }

        //Video Players
        var videoElements = document.querySelectorAll('[data-video-player]');
        for (var i = 0; i < videoElements.length; ++i) {
            new SVHA.Widgets.VideoPlayer({
                selectors: {
                    widgetContainer: videoElements[i],
                }
            })
        }

        //Autocompletes
        var autocompleteElements = document.querySelectorAll('[data-autocomplete]');
        for (var i = 0; i < autocompleteElements.length; ++i) {
            new SVHA.Widgets.Autocomplete({
                selectors: {
                    widgetContainer: autocompleteElements[i],
                }
            })
        }

        this.SpecialistTemplateAccordions = new SVHA.Widgets.AccordionContent({
            firstOpen: false,
            mobileOnly: true,
            resetOnViewportChange: true,
            selectors: {
                widgetContainer: '.specialist-locations',
                accordionItem: '.accordion-item',
                btnToggle: '.btn-accordion-trigger',
                contentBlock: '.accordion-content'
            }
        });

        /*
        Back to top button functionality
        Given its so simple, I've chose not to great a full plugin for it
         */
        $('[data-back-top-top]').click(function () {
            $('html,body').animate({ scrollTop: 0 }, 750);
            return false;
        });


    });

})(jQuery);
